import {ProjectListType} from 'src/types/project'
import {instanceWithToken} from '.'

// 프로젝트 조회
export async function handleProjectList() {
  const response = await instanceWithToken.get(`/auth/prj`)
  return response.data
}

// 프로젝트 버전 상세
export async function handleProjectDetail(data: ProjectListType) {
  const response = await instanceWithToken.get(
    `/auth/prj/detail?prj_id=${data.prj_id}&version_number=${data.version_number}`,
  )
  return response.data
}

// 특정 프로젝트 히스토리
export async function handleProjectHistoryList(prj_id: string) {
  const response = await instanceWithToken.get(`/auth/prj/hst?prj_id=${prj_id}`)
  return response.data
}

// 특정 프로젝트 최신 버전 조회
export async function handleProjectLatestVersion(prj_id: number) {
  const response = await instanceWithToken.get(`/auth/prj/lst?prj_id=${prj_id}`)
  return response.data
}

// 특정 버전 미운영 상태로 변경
export async function handleProjectVersionState(prj_id: string, version_number: string) {
  const response = await instanceWithToken.post(`/auth/prj/um`, {prj_id, version_number})
  return response.data
}
