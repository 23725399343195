import {PropsWithChildren, ReactNode} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {Link, useLocation} from 'react-router-dom'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import FormProvider from 'src/components/providers/ProjectFormProvider'
import theme from 'src/styles/theme'
import {CreateProjectValuesType} from 'src/types/project/create'
import styled from 'styled-components'

interface CreatePrjLayoutType {
  pageTitle: string
  children: ReactNode
  handleSubmit: (e: CreateProjectValuesType) => Promise<boolean | undefined>
  submitButtonTitle: string
  methods: UseFormReturn<CreateProjectValuesType, any, undefined>
  defaultValue?: any
  onChange?: any
}

function CreatePrjLayout({
  children,
  pageTitle,
  submitButtonTitle,
  handleSubmit,
  methods,
  defaultValue,
  onChange,
}: PropsWithChildren<CreatePrjLayoutType>) {
  const {pathname: router} = useLocation()

  return (
    <StyledWrapper>
      <StyledInWrapper>
        <StyledPageTitle>{pageTitle}</StyledPageTitle>
        {router == '/create' && <span>새로운 프로젝트를 생성합니다.</span>}

        {router == '/createVersion' && (
          <>
            <span>특정 프로젝트의 버전을 생성합니다</span>
            <p>
              새로운 프로젝트를 생성하시겠습니까?{' '}
              <StyledLink to="/create">프로젝트 생성</StyledLink>
            </p>
          </>
        )}

        <FormProvider
          methods={methods}
          onSubmit={handleSubmit}
          submitButtonTitle={submitButtonTitle}>
          <StyledRowWrapper>{children}</StyledRowWrapper>

          {router == '/createVersion' ? (
            <CreatePrjLabelInput
              type="prj_description"
              label="Core Changed"
              subTitle="프로젝트의 주요 변경 사항을 작성해 주세요."
              height="100px"
              defaultValue={defaultValue?.data.prj_description}
            />
          ) : (
            <CreatePrjLabelInput
              type="prj_description"
              label="프로젝트 설명"
              height="100px"
              defaultValue={defaultValue?.data.prj_description}
            />
          )}
          <StyledRowWrapper>
            <StyledColumnWrapper>
              <CreatePrjLabelInput type="prj_dev_user" label="개발 담당자" methods={methods} />
              <CreatePrjLabelInput type="prj_sec_user" label="보안 담당자" methods={methods} />

              <CreatePrjLabelInput
                type="prj_lnk"
                label="링크"
                width="600px"
                defaultValue={defaultValue?.data.prj_lnk}
              />
            </StyledColumnWrapper>
            {router.includes('/project/modify') ? (
              <CreatePrjLabelInput
                type="files"
                label="업로드"
                methods={methods}
                defaultValue={defaultValue?.data.file}
                onChange={onChange}
              />
            ) : (
              <CreatePrjLabelInput type="files" label="업로드" methods={methods} />
            )}
          </StyledRowWrapper>
        </FormProvider>
      </StyledInWrapper>
    </StyledWrapper>
  )
}

export default CreatePrjLayout

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  width: 100%;
`

const StyledInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 100px 0 100px;
`

const StyledPageTitle = styled.span`
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.7rem;
`

const StyledLink = styled(Link)`
  all: unset;
  color: ${theme.color.blue700};
  font-weight: ${theme.fontWeight.bold};
  cursor: pointer;
`

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`
