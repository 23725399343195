/* eslint-disable  @typescript-eslint/no-explicit-any */
import {FieldErrors, FieldValues, UseFormRegister, useFormContext} from 'react-hook-form'
import {authInput} from 'src/constants/inputForm'

import {SignFormValuesType} from 'src/types/user'

interface AuthInput {
  register: UseFormRegister<SignFormValuesType>
  errors: FieldErrors<FieldValues>
  rules: {
    required?: string
    pattern?: {
      value: RegExp | any
      message: string
    }
    minLength?: {
      value: number
      message: string
    }
    maxLength?: {
      value: number
      message: string
    }
    validate?: any
  }
  watch?: any
}

function useAuthInput(type: string): AuthInput {
  const matchInput = authInput.find(input => input.type === type)

  const {
    register,
    formState: {errors},
    watch,
  } = useFormContext<SignFormValuesType>()

  const required = matchInput?.required
  const pattern = matchInput?.pattern
  const minLength = matchInput?.minLength
  const maxLength = matchInput?.maxLength
  const validate = matchInput?.validate

  const rules = {
    required: required,
    pattern: pattern,
    minLength: minLength,
    maxLength: maxLength,
    validate: validate,
  }

  return {rules, register, errors, watch}
}

export default useAuthInput
