import {useQuery} from '@tanstack/react-query'
import {ReactNode} from 'react'
import {useNavigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectList} from 'src/apis/project'
import Loading from 'src/components/Loading'
import Table from 'src/components/table/Table'
import {ProjectColumnData} from 'src/constants/ColumnData'
import QUERY_KEYS from 'src/constants/queryKey'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}

function DashboardLayout({children}: Props) {
  const navigate = useNavigate()
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.prjList],
    queryFn: () => handleProjectList(),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  const handleRowSelect = (rowData: any) => {
    navigate(`/project/detail/${rowData?.original?.prj_id}`, {
      state: {versionNumber: `${rowData?.original?.version_number}`},
    })
  }
  return (
    <StyledLayoutWrapper>
      <StyledWrapper>
        <StyledTitle>DAS SDL</StyledTitle>
        <StyledSubTitle>다스버스 SDL 플랫폼</StyledSubTitle>
        <StyledInWrapper>{children}</StyledInWrapper>
      </StyledWrapper>

      <Table
        title="Project"
        subTitle={`${getAuthStateAtom.userLevel == 1 ? 'DAS SDL 전체' : getAuthStateAtom && getAuthStateAtom.userName + '님의'} 프로젝트`}
        data={data.data}
        columns={ProjectColumnData}
        onRowSelect={handleRowSelect}
      />
    </StyledLayoutWrapper>
  )
}

export default DashboardLayout

const StyledLayoutWrapper = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
`

const StyledWrapper = styled.div`
  padding: 10px 35px;
  background-color: ${theme.color.background};
`

const StyledInWrapper = styled.div`
  margin-top: 20px;
`

const StyledTitle = styled.span`
  font-weight: ${theme.fontWeight.bold};
  font-size: 2.4rem;
`

const StyledSubTitle = styled.p`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1rem;
`
