import Lottie from 'lottie-react'

interface ILottieWrapper {
  lottieData: object
  loop?: boolean
}

function LottieWrapper({lottieData, loop = false}: ILottieWrapper) {
  return <Lottie animationData={lottieData} loop={loop} autoplay={true} />
}

export default LottieWrapper
