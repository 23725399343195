import Inform from 'src/components/footer/Inform'
import theme from 'src/styles/theme'
import styled from 'styled-components'

function Footer() {
  return (
    <StyledWrapper>
      <StyledInWrapper>
        <StyledTitle>DAS SDL</StyledTitle>
        <StyledInformWrapper>
          <Inform title="문의전화" inform="02-6495-2167" />
          <Inform title="이메일" inform="seulhyi@dasvers.com" />
        </StyledInformWrapper>
        <hr />

        <StyledCopyRightWrapper>
          <span>Copyright © Develop, Design by 최지완, 양진호, 한슬희. All rights reserved.</span>
        </StyledCopyRightWrapper>
      </StyledInWrapper>
    </StyledWrapper>
  )
}
export default Footer

const StyledWrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 12rem;
  color: ${theme.color.gray700};
  background-color: ${theme.color.background};
  font-size: 0.812rem;
  font-weight: ${theme.fontWeight.light};
  border-top: 3px solid ${theme.color.gray100};
  margin-top: 50px;
`

const StyledInWrapper = styled.div`
  padding: 0 20px;
`

const StyledTitle = styled.div`
  all: unset;
  color: ${theme.color.gray500};
  font-weight: ${theme.fontWeight.bold};
  font-size: 0.94rem;
`

const StyledInformWrapper = styled.div`
  margin: 8px 0;
`

const StyledCopyRightWrapper = styled.div`
  margin-top: 8px;
`
