import {useQuery} from '@tanstack/react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {getNotiInfoDetail} from 'src/apis/notice'
import Button from 'src/components/button'
import Loading from 'src/components/Loading'
import NoticeTable from 'src/components/table/notice'
import QUERY_KEYS from 'src/constants/queryKey'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'

//* 공지사항 detail page
function NoticeDetail() {
  const {notiId} = useParams()
  const navigate = useNavigate()
  const authState = useRecoilValue(authStateAtom)

  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.noticeDetail, notiId],
    queryFn: () => getNotiInfoDetail(notiId!),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <StyledWrapper>
        <StyledTitle>DAS SDL 공지사항</StyledTitle>
        <p>다스버스 SDL 공지사항</p>
        {authState.userLevel === 1 && (
          <StyledBtnWrapper>
            <Button width="100px" height="40px" onClick={() => navigate('/notice/create')}>
              새 글 쓰기
            </Button>
          </StyledBtnWrapper>
        )}
        <StyledInWrapper>
          <StyledTitle>{data?.notice_title}</StyledTitle>
          <StyledInfo>
            {data?.user_name} {data?.rgst_dtm}
          </StyledInfo>
          <StyledDescription>{data?.notice_description}</StyledDescription>
        </StyledInWrapper>
      </StyledWrapper>
      <NoticeTable />
    </>
  )
}

export default NoticeDetail

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 40px 100px 0px 100px;
`

const StyledInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
`

const StyledBtnWrapper = styled.div`
  position: absolute;
  right: 70px;
  top: 150px;
`

const StyledTitle = styled.span`
  font-size: 2rem;
  font-weight: ${theme.fontWeight.bold};
`

const StyledInfo = styled.div`
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.light};

  border-bottom: 1px solid ${theme.color.gray700};
  padding-bottom: 20px;
`

const StyledDescription = styled.div`
  min-height: 350px;
  height: auto;
  border-bottom: 1px solid ${theme.color.gray700};
  margin-top: 50px;
  font-size: 1.35rem;
  font-weight: ${theme.fontWeight.light};
`
