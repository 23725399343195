/* eslint-disable  @typescript-eslint/no-explicit-any */
import {FieldErrors, FieldValues, UseFormRegister, useFormContext} from 'react-hook-form'
import {createInput} from 'src/constants/inputForm'
import {CreateProjectValuesType} from 'src/types/project/create'

interface CreateInput {
  register: UseFormRegister<CreateProjectValuesType>
  errors: FieldErrors<FieldValues>
  rules: {
    required?: string
    pattern?: {
      value: RegExp | any
      message: string
    }
  }
  watch?: any
  setValue?: any
}

function useCreateInput(type: string): CreateInput {
  const matchInput = createInput.find(input => input.type === type)

  const {
    register,
    formState: {errors},
    watch,
    setValue,
  } = useFormContext<CreateProjectValuesType>()

  const required = matchInput?.required
  const pattern = matchInput?.pattern

  const rules = {
    required: required,
    pattern: pattern,
  }

  return {rules, register, errors, watch, setValue}
}

export default useCreateInput
