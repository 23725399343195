import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {handleCreatePrj} from 'src/apis/project/create'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import CreatePrjLayout from 'src/components/layout/CreatePrjLayout'
import {API_STATUS_FILE, API_STATUS_SUCCESS} from 'src/constants/api'
import {CreateProjectValuesType} from 'src/types/project/create'

//* 프로젝트 생성 page
function Create() {
  const methods = useForm<CreateProjectValuesType>({mode: 'all'})
  const navigate = useNavigate()

  const onSubmit = async (data: any) => {
    const dataFormData = new FormData()
    const filesValue = methods.getValues('files')

    const MAX_FILE_SIZE = 5 * 1024 * 1024

    for (const file of filesValue) {
      if (file.size > MAX_FILE_SIZE) {
        methods.setError('files', {message: '파일의 크기가 너무 큽니다.'}, {shouldFocus: true})
        return false
      }
    }

    for (const key in data) {
      if (key == 'files') {
        if (filesValue.length == 1) {
          dataFormData.append(key, data[key][0])
        } else {
          for (let i = 0; i < filesValue.length; i++) {
            dataFormData.append(key, data[key][i])
          }
        }
      } else {
        dataFormData.append(key, data[key])
      }
    }

    try {
      const response = await handleCreatePrj(dataFormData)

      if (response?.status == API_STATUS_FILE) {
        methods.setError('files', {message: '파일의 크기가 너무 큽니다.'}, {shouldFocus: true})
      }

      if (response?.status === API_STATUS_SUCCESS) {
        navigate('/main')
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <CreatePrjLayout
        pageTitle="프로젝트 생성"
        submitButtonTitle="등록"
        methods={methods}
        handleSubmit={onSubmit}>
        <CreatePrjLabelInput
          type="prj_name"
          label="프로젝트 명"
          subTitle="프로젝트 명을 입력해 주세요."
          width="500px"
        />
        <CreatePrjLabelInput
          type="prj_start_version"
          label="버전"
          subTitle="v[Major Version].[Minor Version].[Patches] 형식으로 작성해 주세요."
          width="600px"
        />
      </CreatePrjLayout>
    </FormProvider>
  )
}

export default Create
