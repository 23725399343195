import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectLatestVersion} from 'src/apis/project'
import {handleCreatePrjVersion} from 'src/apis/project/create'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import CreatePrjLayout from 'src/components/layout/CreatePrjLayout'
import {API_STATUS_400, API_STATUS_FILE, API_STATUS_SUCCESS} from 'src/constants/api'
import {ERROR_VERSION_LATEST_CHECK} from 'src/constants/inputMessage'
import {prjIdAtom, prjNameAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import {CreateProjectValuesType} from 'src/types/project/create'
import compareVersions from 'src/utils/compareVersions'
import {styled} from 'styled-components'

//* 프로젝트 버전 생성 page
function CreateVersion() {
  const methods = useForm<CreateProjectValuesType>({mode: 'all'})
  const navigate = useNavigate()

  const getPrjIdAtom = useRecoilValue(prjIdAtom)
  const getPrjNameAtom = useRecoilValue(prjNameAtom)

  methods.setValue('prj_name', getPrjNameAtom)
  methods.setValue('prj_id', getPrjIdAtom)

  const onSubmit = async (data: any) => {
    const inputVersion = methods.watch('version_number')
    const latestVersionResponse = await handleProjectLatestVersion(getPrjIdAtom)

    if (compareVersions(inputVersion, latestVersionResponse.data.version_number) == -1) {
      methods.setError('version_number', {message: ERROR_VERSION_LATEST_CHECK}, {shouldFocus: true})
      return false
    }

    const dataFormData = new FormData()
    const filesValue = methods.getValues('files')
    const MAX_FILE_SIZE = 5 * 1024 * 1024

    for (const file of filesValue) {
      if (file.size > MAX_FILE_SIZE) {
        methods.setError('files', {message: '파일의 크기가 너무 큽니다.'}, {shouldFocus: true})
        return false
      }
    }

    for (const key in data) {
      if (key == 'files') {
        if (filesValue.length == 1) {
          dataFormData.append(key, data[key][0])
        } else {
          for (let i = 0; i < filesValue.length; i++) {
            dataFormData.append(key, data[key][i])
          }
        }
      } else {
        dataFormData.append(key, data[key])
      }
    }

    try {
      const response = await handleCreatePrjVersion(dataFormData)

      if (response?.status == API_STATUS_FILE) {
        methods.setError('files', {message: '파일의 크기가 너무 큽니다.'}, {shouldFocus: true})
      }

      if (response?.status == API_STATUS_400) {
        methods.setError(
          'version_number',
          {message: ERROR_VERSION_LATEST_CHECK},
          {shouldFocus: true},
        )
      }

      if (response?.status === API_STATUS_SUCCESS) {
        navigate('/main')
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <CreatePrjLayout
        pageTitle="버전 생성"
        submitButtonTitle="등록"
        methods={methods}
        handleSubmit={onSubmit}>
        <StyledWrapper>
          <StyledLabel>
            현재 프로젝트: <b>{getPrjNameAtom}</b>
          </StyledLabel>
          <StyledSubTitle>[{getPrjNameAtom}]의 버전을 생성합니다.</StyledSubTitle>
        </StyledWrapper>

        <CreatePrjLabelInput
          type="version_number"
          label="버전"
          subTitle="v[Major Version].[Minor Version].[Patches] 형식으로 작성해 주세요."
          width="600px"
        />
      </CreatePrjLayout>
    </FormProvider>
  )
}

export default CreateVersion

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledSubTitle = styled.span`
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.light};
  color: ${theme.color.gray700};
`

const StyledLabel = styled.label`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.3rem;

  b {
    color: ${theme.color.blue700};
  }
`
