import {useQuery} from '@tanstack/react-query'
import {getNotiInfo} from 'src/apis/notice'
import Loading from 'src/components/Loading'
import Table from 'src/components/table/Table'
import {NoticeColumnData} from 'src/constants/ColumnData'
import QUERY_KEYS from 'src/constants/queryKey'

interface Props {
  title?: string
  subTitle?: string
}

function NoticeTable({title, subTitle}: Props) {
  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.notice],
    queryFn: () => getNotiInfo(),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  return <Table title={title} subTitle={subTitle} data={data.data} columns={NoticeColumnData} />
}

export default NoticeTable
