import {projectStatus} from 'src/constants/status'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface StatusProps {
  title: string
}

function Status({title}: StatusProps) {
  const status = projectStatus.find(status => status.title === title)

  if (!status) return null

  return (
    <StyledWrapper color={status.color} bgColor={status.bgColor}>
      {title}
    </StyledWrapper>
  )
}

export default Status

const StyledWrapper = styled.div<{color?: string; bgColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 30px;
  border-radius: 15px;
  margin: 10px 0;

  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${props => (props.color ? props.color : theme.color.white)};
  background-color: ${props => props.bgColor};
`
