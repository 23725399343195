const handleDownload = async (filePath: string, fileName: string) => {
  try {
    const response = await fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })

    const blob = await response.blob()
    // 링크 생성
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Download failed:', error)
  }
}
export default handleDownload
