import {NoticeCreateType, NoticeDetailResponseType} from 'src/types/notice'
import {get, instanceWithToken} from '.'

// 공지사항 조회
export async function getNotiInfo() {
  const response = await instanceWithToken.get('/auth/notice')
  return response.data
}

// 공지사항 상세 조회
export async function getNotiInfoDetail(noti_id: string) {
  const response = await get<NoticeDetailResponseType>(`/auth/notice/detail?noticeId=${noti_id}`)
  return response.data.data
}

// 공지사항 등록
export async function handleAddNoti(data: NoticeCreateType) {
  const response = await instanceWithToken.post(`/auth/notice/create`, data)
  return response.data
}
