import {ProcessInfoType} from 'src/types/process'
import {instanceFiles, instanceWithToken} from '.'

//프로세스
// 프로세스 단계별 조회
export async function handleProcessInfo(data: ProcessInfoType) {
  const response = await instanceWithToken.get('/auth/prc', {params: data})
  return response.data
}

// 프로세스 최종 등록
export async function handleRegistProcess(data: any) {
  const response = await instanceFiles.post('/auth/prc/lst', data)
  return response.data
}

//코멘트
// 프로세스 코멘트 생성
export async function handleCreateComment(data: any) {
  const response = await instanceFiles.post('/auth/prc/comment', data)
  return response.data
}

// 프로세스 코멘트 수정
export async function handleUpdateComment(data: any) {
  const response = await instanceFiles.post('/auth/prc/comment/update', data)
  return response.data
}

// 프로세스 코멘트 삭제
export async function handleDeleteComment(commentId: number) {
  const response = await instanceWithToken.post('/auth/prc/comment/delete', {
    comm_id: commentId,
  })
  return response.data
}

// 프로세스 단계별 승인
export async function handleAcceptProcess(step_id: number, rgst_user_id: number) {
  const response = await instanceWithToken.post('/prj/agrStp', {step_id, rgst_user_id})
  return response.data
}
