import {useQuery} from '@tanstack/react-query'
import {handleDvList} from 'src/apis/sign'
import Loading from 'src/components/Loading'
import Table from 'src/components/table/Table'
import {AccountColumnData} from 'src/constants/ColumnData'
import QUERY_KEYS from 'src/constants/queryKey'
import styled from 'styled-components'

//* 계정 리스트 page
function Account() {
  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.dvList],
    queryFn: () => handleDvList(),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <StyledWrapper>
      <Table
        title="People"
        subTitle="가입 계정 리스트"
        data={data.data}
        columns={AccountColumnData}
      />
    </StyledWrapper>
  )
}

export default Account

const StyledWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`
