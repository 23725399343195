import {ReactNode} from 'react'
import Footer from 'src/components/footer'
import Header from 'src/components/header'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}

function DefaultLayout({children}: Props) {
  return (
    <StyledWrapper>
      <Header />
      {children}
      <Footer />
    </StyledWrapper>
  )
}

export default DefaultLayout

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
`
