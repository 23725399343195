import {ReactNode} from 'react'
import {UseFormReturn} from 'react-hook-form'
import Button from 'src/components/button'
import {SignFormValuesType} from 'src/types/user'
import {styled} from 'styled-components'

interface Props {
  children: ReactNode
  onSubmit: (e: SignFormValuesType) => Promise<boolean | undefined>
  submitButtonTitle: string
  methods: UseFormReturn<SignFormValuesType, any, undefined>
}

function SignFormProvider({children, onSubmit, submitButtonTitle, methods}: Props) {
  const handleOnSubmit = async (data: SignFormValuesType) => {
    await onSubmit(data)
  }

  return (
    <StyledFormWrapper onSubmit={methods.handleSubmit(handleOnSubmit)}>
      {children}
      <Button type="submit" width="370px">
        {submitButtonTitle}
      </Button>
    </StyledFormWrapper>
  )
}

export default SignFormProvider

const StyledFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
