import {Navigate, Outlet} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {authStateAtom} from 'src/stores/user'

//* authState에 따른 router 처리
function AuthStateRouter() {
  const authState = useRecoilValue(authStateAtom)
  const restrictedPaths = ['/create', '/createVersion', '/account']

  if (authState.isAuthenticated && (location.pathname === '/' || location.pathname === '/signup')) {
    return <Navigate to="/main" />
  }

  if (
    authState.isAuthenticated &&
    authState.userLevel === 2 &&
    restrictedPaths.includes(location.pathname)
  ) {
    return <Navigate to="/main" />
  }

  if (
    !authState.isAuthenticated &&
    location.pathname !== '/' &&
    location.pathname !== '/signup' &&
    location.pathname !== '/forgotPassword' &&
    location.pathname !== '/changePassword'
  ) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default AuthStateRouter
