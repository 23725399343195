import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'recoilPersist',
  storage: localStorage,
})

export const authStateAtom = atom({
  key: 'authStateAtom',
  default: {
    isAuthenticated: false,
    userName: '',
    userId: 0,
    userLevel: 2,
    token: '',
  },
  effects_UNSTABLE: [persistAtom],
})
