import SubTab from 'src/components/tab/SubTab'
import theme from 'src/styles/theme'

interface TabProps {
  data: any[]
  selected: any
  handleClick: (props: {index: number}) => void
  currentStep: number
}

function Tab({data = [], selected = '', handleClick, currentStep}: TabProps) {
  return (
    <>
      {data.map((item, index) => {
        const isSelected = selected === item.projectId
        const isDisabled = index > currentStep
        return (
          <div key={index}>
            <SubTab
              title={item.processId}
              index={index}
              style={{
                color: isDisabled ? '#b0b0b0' : isSelected ? '#0969DA' : '#b0b0b0',
                border: isDisabled
                  ? 'none'
                  : isSelected
                    ? `1px solid ${theme.color.gray700}`
                    : 'none',
                borderWidth: isDisabled ? 'none' : isSelected ? '1px 1px 0px 1px' : 'none',
                borderRadius: '10px 10px 0px 0px',
                borderColor: isDisabled
                  ? ''
                  : isSelected
                    ? `${theme.color.gray700} ${theme.color.gray700} ${theme.color.red} ${theme.color.gray700}`
                    : '',
                outline: isDisabled
                  ? 'none'
                  : isSelected
                    ? `1px solid ${theme.color.white}`
                    : 'none',
                fontWeight: `${theme.fontWeight.bold}`,
                backgroundColor: isDisabled ? '#f0f0f0' : isSelected ? '#ffffff' : undefined,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => !isDisabled && handleClick({index})}
            />
          </div>
        )
      })}
    </>
  )
}

export default Tab
