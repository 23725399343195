import React, {useEffect, useState} from 'react'
import {useRecoilState, useSetRecoilState} from 'recoil'
import removeIcon from 'src/assets/icons/remove.webp'
import {commentResetAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface SimpleUploadProps {
  onFilesChange: (files: FileList | null) => void
  id: string
  existingFiles?: {file_id: string; file_name: string; file_path: string}[]
  test?: any
}

function SimpleUpload({onFilesChange, id, existingFiles}: SimpleUploadProps) {
  const [files, setFiles] = useState<FileList | null>(null)
  // 파일 수정 시, 등록되어있는 파일을 삭제 하기 위한 변수 선언
  const [localExistingFiles, setLocalExistingFiles] = useState(existingFiles || [])
  const setCommentResetAtom = useSetRecoilState(commentResetAtom)
  const commentReset = useRecoilState(commentResetAtom)

  // 100mb limit
  const IMAGE_MAX_SIZE = 100 * 1024 * 1024

  const allowedExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'txt',
    'csv',
    'zip',
    'rar',
  ]

  // 파일 확장자 확인
  const isFileTypeAllowed = (fileName: string) => {
    const fileExtension = fileName.split('.').pop()?.toLowerCase()
    return allowedExtensions.includes(fileExtension || '')
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files)

      // 파일 사이즈와 확장자를 한번에 필터
      const invalidFiles = newFiles.filter(
        file => file.size > IMAGE_MAX_SIZE || !isFileTypeAllowed(file.name),
      )

      if (invalidFiles.length > 0) {
        alert('100MB 이하의 파일 또는 지원되는 파일 형식만 등록 가능합니다.')
      } else {
        setFiles(event.target.files)
        onFilesChange(event.target.files)
      }
    }
  }

  const getFileNames = () => {
    const uploadedFileNames = files
      ? Array.from(files)
          .map(file => file.name)
          .join(', ')
      : ''
    const existingFileNames = localExistingFiles
      ? localExistingFiles.map(file => file.file_name).join(', ')
      : ''

    return uploadedFileNames || existingFileNames || '파일을 선택하세요.'
  }
  const handleClearFiles = () => {
    setFiles(null)
    onFilesChange(null)
    setLocalExistingFiles([])
  }

  useEffect(() => {
    if (existingFiles) {
      // 파일 형식 생성
      setLocalExistingFiles(existingFiles)
      const fileList = new DataTransfer()
      existingFiles.forEach(file => {
        const mockFile = new File([file.file_path], file.file_name)
        fileList.items.add(mockFile)
      })
      setFiles(fileList.files)
      onFilesChange(fileList.files)
    }
  }, [existingFiles])

  useEffect(() => {
    if (commentReset[0] === 'Y') {
      setFiles(null)
      onFilesChange(null)
      setLocalExistingFiles([])
      setCommentResetAtom('N')
    }
  }, [commentReset])

  return (
    <Container>
      <UploadArea>
        <HiddenInput type="file" multiple onChange={handleFileChange} id={`file-upload-${id}`} />
        <FileContainer>
          <UploadButton htmlFor={`file-upload-${id}`}>업로드</UploadButton>
          <UploadText>{getFileNames()}</UploadText>
          {files && files.length > 0 && (
            <ClearButton onClick={handleClearFiles}>
              <img src={removeIcon} alt="Remove" width={20} />
            </ClearButton>
          )}
        </FileContainer>
      </UploadArea>
    </Container>
  )
}

export default SimpleUpload

const Container = styled.div`
  display: flex;
  width: 100%;
`

const UploadArea = styled.div`
  padding-left: 10px;
  display: flex;
  border-radius: 8px;
  height: 45px;
  background-color: ${theme.color.gray100};
  width: 90%;
  padding-right: 10px;
  align-items: center;
`

const HiddenInput = styled.input`
  display: none;
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const UploadButton = styled.label`
  display: inline-block;
  padding: 5px 10px;
  font-size: 1rem;
  color: ${theme.color.black};
  background-color: ${theme.color.purple100};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadText = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`

const ClearButton = styled.button`
  background: none;
  border: none;
  color: ${theme.color.red};
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
  line-height: 1;
`
