import lottieBg from 'src/assets/lottieJSON/loading.json'
import LottieWrapper from 'src/components/LottieWrapper'
import styled from 'styled-components'

function Loading() {
  return (
    <StyledWrapper>
      <LottieWrapper lottieData={lottieBg} />
    </StyledWrapper>
  )
}

export default Loading

const StyledWrapper = styled.div`
  width: 200px;
`
