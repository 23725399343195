import {Dispatch, SetStateAction} from 'react'
import theme from 'src/styles/theme'
import {styled} from 'styled-components'

interface Props {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  totalPages: number
}

function Pagination({currentPage, setCurrentPage, totalPages}: Props) {
  return (
    <StyledPaginationWrapper>
      <StyledPaginationButton
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}>
        이전
      </StyledPaginationButton>
      <StyledPaginationInfo>{currentPage}</StyledPaginationInfo>
      <StyledPaginationButton
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}>
        다음
      </StyledPaginationButton>
    </StyledPaginationWrapper>
  )
}

export default Pagination

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const StyledPaginationButton = styled.button<{disabled: boolean}>`
  padding: 10px;
  margin: 0 10px;
  border-radius: 5px;
  background-color: ${props => (props.disabled ? theme.color.gray300 : theme.color.gray100)};
  color: ${props => (props.disabled ? theme.color.gray500 : theme.color.gray700)};
  font-weight: ${theme.fontWeight.bold};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition:
    background-color 0.15s ease,
    color 0.1s ease;

  &:hover {
    background-color: ${props => !props.disabled && '#5358fd'};
    color: ${props => !props.disabled && theme.color.white};
  }
`

const StyledPaginationInfo = styled.span`
  align-self: center;
  font-size: 1rem;
  color: ${theme.color.gray700};
`
