import {createContext, ReactNode, useContext, useEffect} from 'react'
import {useRecoilState, useResetRecoilState} from 'recoil'
import {authStateAtom} from 'src/stores/user'

interface AuthStateProps {
  isAuthenticated: boolean
  userName: string
  userId: number
  userLevel: number
  token: string
}

interface AuthContextType {
  authState: AuthStateProps
  login: (userData: AuthStateProps) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

function AuthProvider({children}: {children: ReactNode}) {
  const [authState, setAuthState] = useRecoilState(authStateAtom)
  const resetAuthState = useResetRecoilState(authStateAtom)

  const login = (userData: AuthStateProps) => {
    setAuthState({
      ...userData,
      isAuthenticated: true,
    })
    localStorage.setItem('token', userData.token)
  }

  const logout = () => {
    resetAuthState()
    localStorage.removeItem('token')
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setAuthState((prevState: AuthStateProps) => ({
        ...prevState,
        isAuthenticated: true,
        token,
      }))
    } else {
      setAuthState((prevState: AuthStateProps) => ({
        ...prevState,
        isAuthenticated: false,
        token: '',
      }))
    }
  }, [setAuthState])

  return <AuthContext.Provider value={{authState, login, logout}}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext) as AuthContextType
