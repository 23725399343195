import {Outlet} from 'react-router-dom'
import DefaultLayout from 'src/components/layout/DefaultLayout'

function Root() {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  )
}

export default Root
