import styled from 'styled-components'

interface SubTabProps {
  title: string
  onClick?: () => void
  index: number
  style: React.CSSProperties
}

function SubTab({title, onClick = () => {}, index, style}: SubTabProps) {
  return (
    <SubTabContainer style={style} onClick={onClick}>
      <SubTabInnerContainer index={index}>
        <p>{title}</p>
      </SubTabInnerContainer>
    </SubTabContainer>
  )
}

export default SubTab

const SubTabContainer = styled.div`
  width: 120px;
  height: 48px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SubTabInnerContainer = styled.div<{index: number}>``
