import {useQuery} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import {handleProjectDetail} from 'src/apis/project'
import {handleModifyPrj} from 'src/apis/project/create'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import CreatePrjLayout from 'src/components/layout/CreatePrjLayout'
import {API_STATUS_FILE, API_STATUS_SUCCESS} from 'src/constants/api'
import QUERY_KEYS from 'src/constants/queryKey'
import {delYNAtom, devUserAtom, secUserAtom, versionAtom} from 'src/stores/project'
import {authStateAtom} from 'src/stores/user'
import {CreateProjectValuesType} from 'src/types/project/create'

//* 프로젝트 특정 버전 수정 page
//TODO: 타입 수정
function ModifyProject() {
  const {prjId} = useParams()
  const navigate = useNavigate()
  const getVersionAtom = useRecoilValue(versionAtom)
  const getSecUserAtom = useRecoilValue(secUserAtom)
  const getDevUserAtom = useRecoilValue(devUserAtom)
  const getDelYNAtom = useRecoilValue(delYNAtom)
  const getAuthStateAtom = useRecoilValue(authStateAtom)

  const projectData = {
    prj_id: prjId!,
    version_number: getVersionAtom,
  }

  const {data} = useQuery({
    queryKey: [QUERY_KEYS.modifyPrj, prjId],
    queryFn: () => handleProjectDetail(projectData),
  })

  const [newFiles, setFiles] = useState<any>(data?.data.file)

  const methods = useForm<CreateProjectValuesType>({
    mode: 'all',
    defaultValues: {
      prj_name: data?.data.prj_name,
      files: newFiles,
    },
  })

  const onFilesChange = (files: FileList | null) => {
    setFiles(files)
  }

  methods.setValue('prj_id', prjId as any)
  methods.setValue('version_number', getVersionAtom)

  useEffect(() => {
    if (data) {
      if (!methods.getValues('prj_dev_user')) {
        methods.setValue('prj_dev_user', getDevUserAtom)
      }

      if (!methods.getValues('prj_sec_user')) {
        methods.setValue('prj_sec_user', getSecUserAtom)
      }
    }
  }, [data, methods])

  const onSubmit = async (data: any) => {
    const dataFormData = new FormData()

    for (const key in data) {
      if (key == 'files') {
        if (newFiles) {
          Array.from(newFiles).forEach((file: any) => {
            dataFormData.append('files', file)
          })
        }
      } else {
        dataFormData.append(key, data[key])
      }
    }

    // 삭제
    const dataDeleteFormData = new FormData()
    if (getDelYNAtom === 'N') {
      dataDeleteFormData.append('prj_id', prjId!)
      dataDeleteFormData.append('updt_user_id', getAuthStateAtom.userId)
      dataDeleteFormData.append('version_number', getVersionAtom)
      dataDeleteFormData.append('del_yn', getDelYNAtom)
    }

    try {
      const response = await handleModifyPrj(
        getDelYNAtom === 'Y' ? dataFormData : dataDeleteFormData,
      )

      if (response?.status === API_STATUS_FILE) {
        methods.setError('files', {message: '파일의 크기가 너무 큽니다.'}, {shouldFocus: true})
      }

      if (response?.status == API_STATUS_SUCCESS) {
        getDelYNAtom === 'Y' ? navigate(`/project/detail/${projectData.prj_id}`) : navigate('/main')
      }

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <CreatePrjLayout
        pageTitle="프로젝트 수정"
        submitButtonTitle="수정"
        methods={methods}
        handleSubmit={onSubmit}
        defaultValue={data}
        onChange={onFilesChange}>
        <CreatePrjLabelInput
          type="prj_name"
          label="프로젝트 명"
          subTitle="프로젝트 명을 입력해 주세요."
          width="500px"
          defaultValue={data?.data.prj_name}
        />
        <CreatePrjLabelInput type="prj_id" />
        <CreatePrjLabelInput
          type="version_number"
          label="버전"
          subTitle="v[Major Version].[Minor Version].[Patches] 형식으로 작성해 주세요."
          width="600px"
        />
      </CreatePrjLayout>
    </FormProvider>
  )
}

export default ModifyProject
