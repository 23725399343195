import {UseFormReturn} from 'react-hook-form'
import {
  ERROR_EMAIL_CODE_CHECK,
  ERROR_PASSWORD_CHECK,
  ERROR_PASSWORD_NEW_PASSWORD_EMPTY,
} from 'src/constants/inputMessage'
import {SignFormValuesType} from 'src/types/user'

export const validatePasswordCheck = (
  type: 'password' | 'new_password',
  passwordCheck: string,
  methods: UseFormReturn<SignFormValuesType, any, undefined>,
) => {
  const password = methods.watch('login_pw')
  const currentPassword = methods.watch('current_password')
  const newPassword = methods.watch('new_password')

  if (type === 'password' && password !== passwordCheck) {
    methods.setError('pw_check', {
      type: 'validate',
      message: ERROR_PASSWORD_CHECK,
    })
    return false
  }

  if (type === 'new_password' && newPassword == currentPassword) {
    methods.setError('new_password', {
      type: 'validate',
      message: ERROR_PASSWORD_NEW_PASSWORD_EMPTY,
    })
    return false
  }

  if (type === 'new_password' && newPassword !== passwordCheck) {
    methods.setError('pw_check', {
      type: 'validate',
      message: ERROR_PASSWORD_CHECK,
    })
    return false
  }

  return true
}

export const validateEmailCheck = (
  emailCodeVerified: boolean,
  methods: UseFormReturn<SignFormValuesType, any, undefined>,
) => {
  if (!emailCodeVerified) {
    methods.setError('login_id', {
      type: 'validate',
      message: ERROR_EMAIL_CODE_CHECK,
    })
    return false
  }
  return true
}
