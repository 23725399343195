import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {handleProjectDetail, handleProjectHistoryList} from 'src/apis/project'
import lottieBg from 'src/assets/lottieJSON/securityProcess.json'
import LottieWrapper from 'src/components/LottieWrapper'
import Progress from 'src/components/progress/Progress'
import ProjectDetail from 'src/components/ProjectDetail'
import Table from 'src/components/table/Table'
import {ProjectHistoryData} from 'src/constants/ColumnData'
import QUERY_KEYS from 'src/constants/queryKey'
import {devUserAtom, prjIdAtom, prjNameAtom, secUserAtom, versionAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import {ProjectHistoryType, ProjectListType} from 'src/types/project'
import styled from 'styled-components'

//* 프로젝트 상세 page
//TODO: projectHistoryData prj_id 변경
function Detail() {
  const {prjId} = useParams()
  const navigate = useNavigate()
  const param = useParams()
  const location = useLocation()
  const getVersionAtom = useRecoilValue(versionAtom)
  const setDevUserAtom = useSetRecoilState(devUserAtom)
  const setSecUserAtom = useSetRecoilState(secUserAtom)

  const setPrjIdAtom = useSetRecoilState(prjIdAtom)
  const setPrjNameAtom = useSetRecoilState(prjNameAtom)

  const projectHistoryData: ProjectHistoryType = {
    prj_id: parseInt(`${param.projectId}`),
  }

  const projectData: ProjectListType = {
    prj_id: prjId!,
    version_number: getVersionAtom,
  }

  const {data: prjHistoryList} = useQuery({
    queryKey: [QUERY_KEYS.prjHistoryList, projectHistoryData],
    queryFn: () => handleProjectHistoryList(prjId!),
  })

  const {data: prjDetail} = useQuery({
    queryKey: [QUERY_KEYS.prjDetail, projectData],
    queryFn: () => handleProjectDetail(projectData),
  })

  const devUserIdArray = prjDetail?.data.dev_user_id.split(',').map(Number)
  const secUserArray = prjDetail?.data.sec_user.split(',').map(Number)

  useEffect(() => {
    setDevUserAtom(devUserIdArray)
    setSecUserAtom(secUserArray)
  }, [devUserIdArray, secUserArray])

  useEffect(() => {
    setPrjIdAtom(prjDetail?.data.prj_id)
    setPrjNameAtom(prjDetail?.data.prj_name)
  }, [prjDetail])

  interface StepProps {
    id?: any
    handleClick?: any
  }

  const handleRowSelect = (rowData: any) => {
    navigate(`/process/${rowData?.original?.prj_id}/${rowData?.original?.version_number}/0`)
  }
  // process 화면으로 이동
  const processDetail = async ({id}: StepProps) => {
    if (prjDetail !== undefined) {
      navigate(`/process/${prjDetail?.data?.prj_id}/${prjDetail?.data?.version_number}/${id}`)
    }
  }

  const projectHistory = Array.isArray(prjHistoryList?.data) ? prjHistoryList.data : []

  return (
    <StyledWrapper>
      <StyledProjectWrapper>
        {prjDetail !== undefined && (
          <>
            <StyledProjectName>{prjDetail.data?.prj_name}</StyledProjectName>

            <StyledProgressWrapper>
              <StyledLottieWrapper>
                <LottieWrapper lottieData={lottieBg} loop={true} />
              </StyledLottieWrapper>

              <div>
                {prjDetail?.data?.step_number == 5 && (
                  <>
                    <StyledExplain>
                      현재 {prjDetail?.data?.prj_name}의 <b>{prjDetail?.data?.version_number}</b>{' '}
                      버전은 <b>[미운영]</b> 상태입니다.
                    </StyledExplain>
                    <StyledExplain>
                      {' '}
                      각 단계별로 이전에 작성한 내용을 볼 수 있으며 작성한 내용이 없을 경우 빈
                      칸으로 보여집니다.
                    </StyledExplain>
                  </>
                )}
                <Progress
                  unStep={prjDetail?.data?.step_number === 5 ? true : false}
                  step={prjDetail?.data?.step_number}
                  handleClick={processDetail}
                />
              </div>
            </StyledProgressWrapper>

            <StyledMiddleWrapper></StyledMiddleWrapper>
            <StyledExplain>
              *해당 프로젝트가 검토중인 버전이 있을 경우 <b>버전 생성</b>이 불가능합니다.
            </StyledExplain>
            <ProjectDetail
              prj_name={prjDetail?.data?.prj_name}
              rgst_dtm={prjDetail?.data?.rgst_dtm}
              step_number={prjDetail?.data?.step_number}
              prj_description={prjDetail?.data?.prj_description}
              version_number={prjDetail?.data?.version_number}
              file={prjDetail?.data?.file}
              dev_user_name={prjDetail?.data?.dev_user_name}
              sec_user_name={prjDetail?.data?.sec_user_name}
              prj_id={prjId!}
              prj_lnk={prjDetail?.data?.prj_lnk}
            />
          </>
        )}
        <MiddleContainer></MiddleContainer>
      </StyledProjectWrapper>
      <Table
        columns={ProjectHistoryData}
        data={projectHistory}
        title="History"
        subTitle="해당 프로젝트의 버전 히스토리"
        onRowSelect={handleRowSelect}
      />
    </StyledWrapper>
  )
}

export default Detail

const StyledWrapper = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-top: 30px;
  background-color: ${theme.color.background};
`

const StyledProjectWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`

const StyledProjectName = styled.p`
  font-size: 1.875rem;
  padding-bottom: 30px;
  font-weight: ${theme.fontWeight.bold};
`

const StyledMiddleWrapper = styled.p`
  height: 20px;
`

const MiddleContainer = styled.p`
  height: 30px;
`

const StyledExplain = styled.span`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 0.85rem;
  color: ${theme.color.gray700};
  letter-spacing: 0.2px;

  b {
    color: ${theme.color.blue700};
  }
`

const StyledProgressWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`

const StyledLottieWrapper = styled.div`
  width: 215px;
`
