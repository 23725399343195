import {useQuery} from '@tanstack/react-query'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {getNotiInfo} from 'src/apis/notice'
import Loading from 'src/components/Loading'
import MainCardLayout from 'src/components/mainCard/Layout'
import Pagination from 'src/components/Pagination'
import QUERY_KEYS from 'src/constants/queryKey'
import theme from 'src/styles/theme'
import {NoticeResponseType} from 'src/types/notice'
import {styled} from 'styled-components'

function Docs() {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 3

  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.notice],
    queryFn: () => getNotiInfo(),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  const noticeData = Array.isArray(data?.data) ? data.data : []

  const totalPages = Math.ceil(noticeData.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedNotice = noticeData.slice(startIndex, endIndex)

  return (
    <MainCardLayout title="Docs">
      {paginatedNotice.map((item: NoticeResponseType) => (
        <StyledInWrapper to={`/notice/detail/${item.noti_id}`} key={item.noti_id}>
          <StyledNoticeTitle>{item.notice_title}</StyledNoticeTitle>
          <StyledNoticeData>
            {item.rgst_dtm} {item.user_name}
          </StyledNoticeData>
        </StyledInWrapper>
      ))}

      {noticeData.length !== 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
      <StyledLinkNotice to="/notice">더보기</StyledLinkNotice>
    </MainCardLayout>
  )
}

export default Docs

const StyledInWrapper = styled(Link)`
  all: unset;
  display: flex;
  flex-direction: column;
  margin: 2px 0;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 10px;

  &:hover {
    background-color: ${theme.color.gray100};
  }
`

const StyledNoticeTitle = styled.span`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.15rem;
`

const StyledNoticeData = styled.span`
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.light};
  font-size: 0.85rem;
`

const StyledLinkNotice = styled(Link)`
  all: unset;
  align-self: flex-end;
  margin-top: auto;
  cursor: pointer;
  color: ${theme.color.gray700};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
`
