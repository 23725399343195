import {ErrorMessage} from '@hookform/error-message'
import useAuthInput from 'src/hooks/useAuthInput'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface LabelInputProps {
  type:
    | 'login_id'
    | 'login_pw'
    | 'user_name'
    | 'pw_check'
    | 'current_password'
    | 'new_password'
    | 'auth_code'
  label?: 'Email' | 'Password' | 'Name' | 'Current Password' | 'New Password' | 'Verify Password'
  width?: string
}

function LabelInput({type, label, width}: LabelInputProps) {
  const {register, errors, rules} = useAuthInput(type)

  return (
    <StyledWrapper>
      {label && <StyledLabel htmlFor={type}>{label}</StyledLabel>}
      <StyledLabelInput
        id={type}
        type={type.includes('pw') ? 'password' : type}
        placeholder={rules.required}
        {...register(type, {
          required: rules.required,
          pattern: rules.pattern,
          minLength: rules.minLength,
        })}
        width={width}
      />
      <ErrorMessage
        name={type}
        errors={errors}
        render={({message}) => <StyledError>{message}</StyledError>}
      />
    </StyledWrapper>
  )
}

export default LabelInput

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled.label`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.3rem;
`

const StyledLabelInput = styled.input<{width?: string}>`
  all: unset;
  width: ${props => (props.width ? props.width : '100%')};
  height: 40px;
  padding-left: 10px;

  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  font-size: 0.9rem;
`

const StyledError = styled.p`
  color: ${theme.color.red};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.semiBold};
`
