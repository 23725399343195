import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import {RecoilRoot} from 'recoil'
import {ErrorBoundary} from './components/ErrorBoundary'
import Loading from './components/Loading'
import MobileWarning from './components/MobileWarning'
import QueryProvider from './components/providers/QueryProvider'
import AuthProvider from './contexts/AuthContext'
import {useMediaQuery} from './hooks/useMediaQuery'
import reportWebVitals from './reportWebVitals'
import Router from './router/Router'
import GlobalStyle from './styles/GlobalStyle'

function App() {
  const isMobile = useMediaQuery(1050)

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <RecoilRoot>
          <QueryProvider>
            <React.StrictMode>
              <GlobalStyle />
              {isMobile ? <MobileWarning /> : ''}
              <AuthProvider>
                <RouterProvider router={Router()} />
              </AuthProvider>
            </React.StrictMode>
          </QueryProvider>
        </RecoilRoot>
      </Suspense>
    </ErrorBoundary>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)

reportWebVitals()
