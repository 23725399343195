import {ReactNode} from 'react'
import theme from 'src/styles/theme'
import {styled} from 'styled-components'

interface Props {
  children: ReactNode
  title: string
  subTitle?: ReactNode
  height?: string
}

function MainCardLayout({children, title, subTitle, height}: Props) {
  return (
    <StyledWrapper height={height}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{subTitle}</StyledDescription>
      <StyledContent>{children}</StyledContent>
    </StyledWrapper>
  )
}

export default MainCardLayout

const StyledTitle = styled.span`
  color: #b3b4b1;
  font-weight: ${theme.fontWeight.bold};
  font-size: 1.5rem;
`

const StyledWrapper = styled.div<{height?: string}>`
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.color.white};
  border-radius: 10px;
  padding: 10px 20px;
  height: ${props => (props.height ? props.height : 'auto')};
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover ${StyledTitle} {
    color: ${theme.color.black};
  }
`

const StyledDescription = styled.div`
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.light};
  font-size: 1.1rem;
`

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`
