import {ReactNode, useState} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import Button from 'src/components/button'
import CreatePrjLabelInput from 'src/components/form/labelInput/create'
import Modal from 'src/components/modal'
import {delYNAtom, requiredAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import {CreateProjectValuesType} from 'src/types/project/create'
import {styled} from 'styled-components'

interface Props {
  children: ReactNode
  onSubmit: (e: CreateProjectValuesType) => Promise<boolean | undefined>
  submitButtonTitle: string
  methods: UseFormReturn<CreateProjectValuesType, any, undefined>
}

//* 수정페이지 내에서는 삭제 기능이 존재합니다.
function ProjectFormProvider({children, onSubmit, submitButtonTitle, methods}: Props) {
  const navigate = useNavigate()

  const {pathname: router} = useLocation()

  const handleOnSubmit = async (data: any) => {
    await onSubmit(data)
    router === '/createVersion' || '/create' ? '' : navigate('/main')
  }

  const setRequiredAtom = useSetRecoilState(requiredAtom)
  const setDelYNAtom = useSetRecoilState(delYNAtom)

  const [modalOpen, setModalOpen] = useState(false)
  const [delYnState, setDelYnState] = useState(false)

  const handleDeleteBtn = () => {
    setModalOpen(!modalOpen)
    setDelYnState(true)
    setRequiredAtom(false)
    setDelYNAtom('N')
  }

  return (
    <StyledFormWrapper onSubmit={methods.handleSubmit(handleOnSubmit)}>
      {children}

      {modalOpen && !delYnState && (
        <Modal
          title="프로젝트 수정"
          onClick={() => {
            setModalOpen(false)
            setDelYNAtom('Y')
          }}
          rightBtnTitle="수정">
          프로젝트를 수정하시겠습니까?
        </Modal>
      )}

      {modalOpen && delYnState && (
        <Modal title="프로젝트 삭제" onClick={() => setModalOpen(false)}>
          프로젝트를 정말 삭제하시겠습니까?
        </Modal>
      )}

      <CreatePrjLabelInput type="rgst_user_id" />
      {router == '/createVersion' && <CreatePrjLabelInput type="prj_name" />}
      <StyledBottomWrapper>
        <StyledBottomWrapper>*여러 번의 검토 후 버튼을 눌러주세요.</StyledBottomWrapper>

        {router.includes(`/project/modify`) ? (
          <StyledButtonGroupWrapper>
            <Button
              width="370px"
              onClick={() => {
                setModalOpen(!modalOpen)
                setDelYnState(false)
              }}>
              수정
            </Button>

            <Button width="370px" bgColor={`${theme.color.red}`} onClick={handleDeleteBtn}>
              삭제
            </Button>
          </StyledButtonGroupWrapper>
        ) : (
          <Button type="submit" width="370px">
            {submitButtonTitle}
          </Button>
        )}
      </StyledBottomWrapper>
    </StyledFormWrapper>
  )
}

export default ProjectFormProvider

const StyledFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
`

const StyledBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.gray700};
`

const StyledButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
