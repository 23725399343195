import {Ref, forwardRef} from 'react'
import theme from 'src/styles/theme'
import {ButtonAttributes, Status} from 'src/types/button'
import styled from 'styled-components'

interface ButtonProps extends ButtonAttributes {
  status?: Status
  width?: string
  height?: string
  bgColor?: string
  color?: string
  fontSize?: string
}

const Button = forwardRef(function Button(
  {
    children,
    type = 'button',
    width,
    height,
    bgColor,
    color,
    fontSize,
    status = 'Enabled',
    ...rest
  }: ButtonProps,
  forwardedRef: Ref<HTMLButtonElement>,
) {
  return (
    <StyledWrapper
      type={type}
      ref={forwardedRef}
      status={status}
      width={width}
      height={height}
      bgColor={bgColor}
      color={color}
      fontSize={fontSize}
      {...rest}>
      {children}
    </StyledWrapper>
  )
})

export default Button

const StyledWrapper = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => (props.width ? props.width : '400px')};
  height: ${props => (props.height ? props.height : '48px')};
  border-radius: 6px;
  color: ${props => (props.color ? props.color : theme.color.white)};
  background-color: ${props => (props.bgColor ? props.bgColor : theme.color.teal)};
  opacity: ${props => (props.status == 'Disabled' ? '45%' : '100%')};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.1rem')};
  font-weight: ${theme.fontWeight.bold};
  cursor: pointer;
`
