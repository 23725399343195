import {createBrowserRouter} from 'react-router-dom'
import ScrollToTop from 'src/components/ScrollToTop'
import ModifyVersion from 'src/pages/developer/[prjId]/ModifyProject'
import Create from 'src/pages/developer/Create'
import CreateVersion from 'src/pages/developer/CreateVersion'
import Main from 'src/pages/Main'
import Account from 'src/pages/manager/account'
import Manual from 'src/pages/Manual'
import NotFound from 'src/pages/NotFound'
import NoticeCreate from 'src/pages/notice/Create'
import NoticeDetail from 'src/pages/notice/detail/[notiId]'
import Notice from 'src/pages/notice/Notice'
import Detail from 'src/pages/project/detail/[detailId]'
import Process from 'src/pages/project/process'
import ChangePassword from 'src/pages/users/ChangePassword'
import ForgotPassword from 'src/pages/users/ForgotPassword'
import Signin from 'src/pages/users/Signin'
import Signup from 'src/pages/users/Signup'
import AuthStateRouter from 'src/router/AuthStateRouter'
import Root from 'src/router/Root'

function Router() {
  return createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <ScrollToTop />
          <Root />
        </>
      ),
      children: [
        {path: '*', element: <NotFound />},
        {
          element: <AuthStateRouter />,
          children: [
            // user
            {path: '/', element: <Signin />},
            {path: '/signup', element: <Signup />},
            {path: '/forgotPassword', element: <ForgotPassword />},
            {path: '/changePassword', element: <ChangePassword />},

            // 공통
            {path: '/main', element: <Main />},
            {path: '/manual', element: <Manual />},
            {path: '/project/detail/:prjId', element: <Detail />},
            {path: '/notice', element: <Notice />},
            {path: '/notice/create', element: <NoticeCreate />},
            {path: '/notice/detail/:notiId', element: <NoticeDetail />},

            // 보안 담당자 & 개발 담당자
            // project/version/process단계(0,1,2,3) - 탭으로 구성
            {path: '/process/:projectId/:versionId/:processId', element: <Process />},

            // 개발 담당자
            {path: '/create', element: <Create />},
            {path: '/createVersion', element: <CreateVersion />},
            {path: '/project/modify/:prjId', element: <ModifyVersion />},

            // 관리자
            {path: '/account', element: <Account />},
          ],
        },
      ],
    },
  ])
}

export default Router
