import {useNavigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import Button from 'src/components/button'
import NoticeTable from 'src/components/table/notice'
import {authStateAtom} from 'src/stores/user'
import styled from 'styled-components'

//* 공지사항 page
function Notice() {
  const navigate = useNavigate()
  const authState = useRecoilValue(authStateAtom)

  return (
    <StyledWrapper>
      <StyledBtnWrapper>
        {authState.userLevel === 1 && (
          <Button width="100px" height="40px" onClick={() => navigate('/notice/create')}>
            새 글 쓰기
          </Button>
        )}
      </StyledBtnWrapper>
      <NoticeTable title="공지" subTitle="SDL 공지" />
    </StyledWrapper>
  )
}

export default Notice

const StyledWrapper = styled.div`
  min-height: 100%;
  width: 100%;
`

const StyledBtnWrapper = styled.div`
  position: absolute;
  right: 70px;
  top: 150px;
`
