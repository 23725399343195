//* 버전 비교 함수
function compareVersions(version: string, latestVersion: string): number {
  const versionParts = version.substr(1).split('.').map(Number)
  const latestVersionParts = latestVersion.substr(1).split('.').map(Number)

  const maxLength = Math.max(versionParts.length, latestVersionParts.length)

  for (let i = 0; i < maxLength; i++) {
    const versionPart = versionParts[i] !== undefined ? versionParts[i] : 0
    const latestVersionPart = latestVersionParts[i] !== undefined ? latestVersionParts[i] : 0

    if (versionPart > latestVersionPart) return 1
    if (versionPart < latestVersionPart) return -1
  }

  return 0
}

export default compareVersions
