import {useQuery} from '@tanstack/react-query'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {handleDvList} from 'src/apis/sign'
import MainCardLayout from 'src/components/mainCard/Layout'
import Pagination from 'src/components/Pagination'
import QUERY_KEYS from 'src/constants/queryKey'
import Loading from 'src/components/Loading'
import theme from 'src/styles/theme'
import {AccountDataType} from 'src/types/user'
import {styled} from 'styled-components'

//* 관리자 전용 컴포넌트
function PeopleCard() {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 4

  const {data, error, isLoading} = useQuery({
    queryKey: [QUERY_KEYS.dvList],
    queryFn: () => handleDvList(),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  const accountData = Array.isArray(data?.data) ? data.data : []

  const totalPages = Math.ceil(accountData.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedAccount = accountData.slice(startIndex, endIndex)

  return (
    <MainCardLayout title="People">
      계정 리스트
      <div>
        {paginatedAccount.map((item: AccountDataType) => (
          <StyledItemWrapper to="/account">
            <StyledColumnWrapper>
              <StyledName>{item.userName}</StyledName>
              <StyledUserLevel>
                {item.userLevel === 1 && '관리자'}
                {item.userLevel === 2 && '보안'}
                {item.userLevel === 3 && '개발'}
              </StyledUserLevel>
            </StyledColumnWrapper>
            <StyledRgstDtm>가입일시 {item.rgstDtm}</StyledRgstDtm>
          </StyledItemWrapper>
        ))}
      </div>
      {accountData.length !== 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
      <StyledLinkNotice to="/account">더보기</StyledLinkNotice>
    </MainCardLayout>
  )
}

export default PeopleCard

const StyledItemWrapper = styled(Link)`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 7px 10px;

  &:hover {
    background-color: ${theme.color.gray100};
  }
`

const StyledColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledName = styled.span`
  font-size: 1.15rem;
  font-weight: ${theme.fontWeight.semiBold};
`

const StyledUserLevel = styled.p`
  font-size: 0.8rem;
  font-weight: ${theme.fontWeight.light};
  color: ${theme.color.gray700};
`

const StyledRgstDtm = styled.span`
  font-size: 0.8rem;
  font-weight: ${theme.fontWeight.light};
  color: ${theme.color.gray700};
`

const StyledLinkNotice = styled(Link)`
  all: unset;
  align-self: flex-end;
  margin-top: auto;
  cursor: pointer;
  color: ${theme.color.gray700};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
`
