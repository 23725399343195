import {useState} from 'react'
import {UseFormReturn} from 'react-hook-form'
import {handleAuthEmailCode, handleSendEmailAuth} from 'src/apis/sign'
import {API_STATUS_FAILED, API_STATUS_SUCCESS} from 'src/constants/api'
import {SignFormValuesType} from 'src/types/user'

//* 이메일 검증 hook
const useEmailVerification = (
  emailValue: string,
  methods: UseFormReturn<SignFormValuesType, any, undefined>,
) => {
  const [isEmailVerificationOpen, setEmailVerificationOpen] = useState(false)
  const [emailCodeVerified, setEmailCodeVerified] = useState(false)
  const [email, setEmail] = useState('')

  // 이메일 코드 전송
  const handleSendEmailCode = async () => {
    await methods.reset({auth_code: ''})
    if (emailValue) {
      setEmail(emailValue)
      try {
        const response = await handleSendEmailAuth({login_id: emailValue})

        if (response?.status === API_STATUS_FAILED) {
          methods.setError(
            'login_id',
            {message: '이미 존재하는 이메일이 있습니다.'},
            {shouldFocus: true},
          )
          setEmailVerificationOpen(false)
        } else if (response?.status === API_STATUS_SUCCESS) {
          setEmailVerificationOpen(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // 이메일 코드 인증
  const handleAuthEmailVerification = async () => {
    const authCode = methods.getValues('auth_code')

    if (authCode) {
      try {
        const response = await handleAuthEmailCode({auth_code: authCode, login_id: emailValue})

        if (response?.status === API_STATUS_SUCCESS) {
          setEmailCodeVerified(true)
          setEmail(emailValue)
        } else {
          setEmailCodeVerified(false)
          methods.reset({auth_code: ''})
          methods.setError(
            'auth_code',
            {message: '인증번호를 다시 확인해 주세요.'},
            {shouldFocus: true},
          )
        }
      } catch (error) {
        setEmailCodeVerified(false)
        methods.setError('login_id', {message: '인증 오류가 발생했습니다.'}, {shouldFocus: true})
      }
    }
  }

  return {
    email,
    emailValue,
    isEmailVerificationOpen,
    emailCodeVerified,
    handleSendEmailCode,
    handleAuthEmailVerification,
    setEmailVerificationOpen,
    setEmailCodeVerified,
  }
}

export default useEmailVerification
