import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {API_BASE_URL} from 'src/constants/api'
import {ResponseType} from 'src/types/user'

export const instance = axios.create({
  baseURL: API_BASE_URL,
})

export const instanceWithToken = axios.create({
  baseURL: API_BASE_URL,
})

export const instanceFiles = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

function responsefulfilledInterceptor(res: AxiosResponse) {
  if (200 <= res.status && res.status < 300) {
    return res
  }
  return Promise.reject(res)
}

function responseRejectedInterceptor(error: AxiosError) {
  return error
}

instance.interceptors.response.use(responsefulfilledInterceptor, responseRejectedInterceptor)

instanceWithToken.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers = {
      ...config.headers,
      token: token,
    } as AxiosRequestConfig['headers']
  }
  return config
})

instanceFiles.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers = {
      ...config.headers,
      token: token,
    } as AxiosRequestConfig['headers']
  }
  return config
})

export const get = async <T>(
  apiUrl: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType<T>>> => {
  const response = await instanceWithToken.get(apiUrl, config)
  return response
}

export const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType<T>>> => {
  const response = await instance.post(url, data, config)
  return response
}
