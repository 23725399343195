function DateFormat(dueDate: Date) {
  const selectedDateTime = new Date(dueDate)
  const year = selectedDateTime.getFullYear()
  const month = String(selectedDateTime.getMonth() + 1).padStart(2, '0')
  const date = String(selectedDateTime.getDate()).padStart(2, '0')

  const formattedDateTime = `${year}-${month}-${date}`

  return formattedDateTime
}

export default DateFormat
