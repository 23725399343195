import {PropsWithChildren, ReactNode} from 'react'
import {UseFormReturn} from 'react-hook-form'
import SignFormProvider from 'src/components/providers/SignFormProvider'
import theme from 'src/styles/theme'
import {SignFormValuesType} from 'src/types/user'
import styled from 'styled-components'

interface AuthLayoutType {
  children: ReactNode
  pageTitle?: string
  buttonTitle: string
  handleSubmit: (data: SignFormValuesType) => Promise<boolean | undefined>
  methods: UseFormReturn<SignFormValuesType, any, undefined>
  bottomContent?: ReactNode
}

function AuthLayout({
  children,
  pageTitle,
  buttonTitle,
  handleSubmit,
  methods,
  bottomContent,
}: PropsWithChildren<AuthLayoutType>) {
  return (
    <StyledWrapper>
      <StyledInWrapper>
        <StyledPageTitle>{pageTitle}</StyledPageTitle>
        <SignFormProvider methods={methods} onSubmit={handleSubmit} submitButtonTitle={buttonTitle}>
          {children}
        </SignFormProvider>
        <StyledBottomWrapper>{bottomContent}</StyledBottomWrapper>
      </StyledInWrapper>
    </StyledWrapper>
  )
}

export default AuthLayout

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
`

const StyledPageTitle = styled.h1`
  text-align: center;
  margin-bottom: 50px;
`

const StyledBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: ${theme.fontWeight.semiBold};
  color: ${theme.color.gray700};
  margin-top: 10px;
`
