import {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {handleSignup} from 'src/apis/sign'
import CommonLink from 'src/components/CommonLink'
import LabelInput from 'src/components/form/labelInput'
import EmailVerification from 'src/components/form/labelInput/EmailVerification'
import SignupRadio from 'src/components/form/radio/SignupRadio'
import AuthLayout from 'src/components/layout/AuthLayout'
import {API_STATUS_SUCCESS} from 'src/constants/api'
import useEmailVerification from 'src/hooks/useEmailVerification'
import {SignFormValuesType, SignUpFormValuesType} from 'src/types/user'
import {validateEmailCheck, validatePasswordCheck} from 'src/utils/validation'

//* 회원가입 page
function Signup() {
  const navigate = useNavigate()

  const methods = useForm<SignFormValuesType>({
    mode: 'all',
    defaultValues: {login_id: '', auth_code: ''},
  })

  const emailValue = methods.watch('login_id')
  const {
    email,
    isEmailVerificationOpen,
    emailCodeVerified,
    handleSendEmailCode,
    handleAuthEmailVerification,
    setEmailVerificationOpen,
    setEmailCodeVerified,
  } = useEmailVerification(emailValue, methods)

  const onSubmit = async (data: SignUpFormValuesType) => {
    const passwordCheck = methods.watch('pw_check')

    if (passwordCheck !== undefined) {
      if (
        !validatePasswordCheck('password', passwordCheck, methods) ||
        !validateEmailCheck(emailCodeVerified, methods)
      ) {
        return false
      }
    }

    try {
      const response = await handleSignup(data)
      if (response?.status === API_STATUS_SUCCESS) {
        navigate('/')
      }
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    if (emailValue !== email || emailValue == '') {
      setEmailVerificationOpen(false)
      setEmailCodeVerified(false)
    } else if (emailValue && !emailCodeVerified) {
      setEmailVerificationOpen(true)
    }
  }, [email, emailValue, emailCodeVerified])

  return (
    <FormProvider {...methods}>
      <AuthLayout
        pageTitle="Signup"
        buttonTitle="회원가입"
        handleSubmit={onSubmit}
        methods={methods}
        bottomContent={
          <>
            <span>이미 계정이 있으신가요?</span>
            <CommonLink to="/" title="Sign In" />
          </>
        }>
        <LabelInput type="user_name" label="Name" width="360px" />
        <LabelInput type="login_id" label="Email" width="360px" />

        <EmailVerification
          email={email}
          emailValue={emailValue}
          emailCodeVerified={emailCodeVerified}
          isEmailVerificationOpen={isEmailVerificationOpen}
          handleSendEmailCode={handleSendEmailCode}
          handleAuthEmailVerification={handleAuthEmailVerification}
        />

        <LabelInput type="login_pw" label="Password" width="360px" />
        <LabelInput type="pw_check" label="Verify Password" width="360px" />
        <SignupRadio />
      </AuthLayout>
    </FormProvider>
  )
}

export default Signup
