import {
  EMAIL_STANDARD,
  ERROR_EMAIL_CHECK,
  ERROR_EMAIL_CODE_EMPTY,
  ERROR_EMAIL_EMPTY,
  ERROR_NICKNAME_EMPTY,
  ERROR_NICKNAME_VALIDATION,
  ERROR_NOTICE_DESCRIPTION_EMPTY,
  ERROR_NOTICE_TITLE_EMPTY,
  ERROR_PASSWORD_CURRENT,
  ERROR_PASSWORD_CURRENT_EMPTY,
  ERROR_PASSWORD_EMPTY,
  ERROR_PASSWORD_NEW_PASSWORD_EMPTY,
  ERROR_PASSWORD_SECOND_EMPTY,
  ERROR_PASSWORD_VALIDATION,
  ERROR_PROJECT_CHOOSE_EMPTY,
  ERROR_PROJECT_DESCRIPTION_EMPTY,
  ERROR_PROJECT_DEV_USER_EMPTY,
  ERROR_PROJECT_FILE_EMPTY,
  ERROR_PROJECT_LINK_EMPTY,
  ERROR_PROJECT_LINK_STANDARD,
  ERROR_PROJECT_NAME_EMPTY,
  ERROR_PROJECT_SEC_USER_EMPTY,
  ERROR_PROJECT_VERSION_EMPTY,
  ERROR_VERSION_STANDARD_STANDARD,
  LINK_STANDARD,
  NICKNAME_STANDARD,
  PASSWORD_STANDARD,
  VERSION_STANDARD,
} from 'src/constants/inputMessage'

export const noticeCreateInput = [
  {
    type: 'notice_title',
    required: ERROR_NOTICE_TITLE_EMPTY,
  },
  {
    type: 'notice_description',
    required: ERROR_NOTICE_DESCRIPTION_EMPTY,
  },
  {
    type: 'rgst_user_id',
  },
]

export const createInput = [
  {
    type: 'prj_name',
    required: ERROR_PROJECT_NAME_EMPTY,
  },
  {
    type: 'prj_id',
    required: ERROR_PROJECT_CHOOSE_EMPTY,
  },
  {
    type: 'rgst_user_id',
  },
  {
    type: 'updt_user_id',
  },
  {
    type: 'del_yn',
  },
  {
    type: 'prj_description',
    required: ERROR_PROJECT_DESCRIPTION_EMPTY,
  },
  {
    type: 'prj_start_version',
    required: ERROR_PROJECT_VERSION_EMPTY,
    pattern: {value: VERSION_STANDARD, message: ERROR_VERSION_STANDARD_STANDARD},
  },
  {
    type: 'version_number',
    required: ERROR_PROJECT_VERSION_EMPTY,
    pattern: {value: VERSION_STANDARD, message: ERROR_VERSION_STANDARD_STANDARD},
  },
  {
    type: 'prj_lnk',
    required: ERROR_PROJECT_LINK_EMPTY,
    pattern: {value: LINK_STANDARD, message: ERROR_PROJECT_LINK_STANDARD},
  },
  {
    type: 'prj_dev_user',
    required: ERROR_PROJECT_DEV_USER_EMPTY,
  },
  {
    type: 'prj_sec_user',
    required: ERROR_PROJECT_SEC_USER_EMPTY,
  },
  {
    type: 'files',
    required: ERROR_PROJECT_FILE_EMPTY,
  },
]

export const authInput = [
  {
    type: 'login_id',
    required: ERROR_EMAIL_EMPTY,
    pattern: {value: EMAIL_STANDARD, message: ERROR_EMAIL_CHECK},
  },
  {
    type: 'auth_code',
    required: ERROR_EMAIL_CODE_EMPTY,
  },
  {
    type: 'login_pw',
    required: ERROR_PASSWORD_EMPTY,
    pattern: {value: PASSWORD_STANDARD, message: ERROR_PASSWORD_VALIDATION},
  },
  {
    type: 'pw_check',
    required: ERROR_PASSWORD_SECOND_EMPTY,
  },
  {
    type: 'user_name',
    required: ERROR_NICKNAME_EMPTY,
    pattern: {value: NICKNAME_STANDARD, message: ERROR_NICKNAME_VALIDATION},
  },
  {
    type: 'current_password',
    required: ERROR_PASSWORD_CURRENT_EMPTY,
    minLength: {value: 8, message: ERROR_PASSWORD_VALIDATION},
    validate: ERROR_PASSWORD_CURRENT,
  },
  {
    type: 'new_password',
    required: ERROR_PASSWORD_NEW_PASSWORD_EMPTY,
    pattern: {value: PASSWORD_STANDARD, message: ERROR_PASSWORD_VALIDATION},
    maxLength: {value: 8, message: ERROR_PASSWORD_VALIDATION},
  },
  {
    type: 'user_level',
    required: ERROR_PASSWORD_NEW_PASSWORD_EMPTY,
  },
]
