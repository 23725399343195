import {ErrorMessage} from '@hookform/error-message'
import {useQuery} from '@tanstack/react-query'
import {ChangeEvent, useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import Select from 'react-select'
import {useRecoilValue} from 'recoil'
import {handleProjectList} from 'src/apis/project'
import Dropdown from 'src/components/form/dropdown'
import {FILES_ACCEPT} from 'src/constants/inputMessage'
import QUERY_KEYS from 'src/constants/queryKey'
import useCreateInput from 'src/hooks/useCreateInput'
import useToggle from 'src/hooks/useToggle'
import {prjNameAtom, requiredAtom} from 'src/stores/project'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface LabelInputProps {
  type:
    | 'prj_name'
    | 'prj_id'
    | 'prj_description'
    | 'prj_start_version'
    | 'prj_lnk'
    | 'prj_dev_user'
    | 'prj_sec_user'
    | 'files'
    | 'rgst_user_id'
    | 'version_number'
    | 'updt_user_id'
    | 'del_yn'
  label?:
    | '프로젝트 명'
    | '버전'
    | '프로젝트 설명'
    | '개발 담당자'
    | '보안 담당자'
    | '링크'
    | '업로드'
    | '프로젝트 선택'
    | 'Core Changed'
  width?: string
  height?: string
  subTitle?: string
  required?: boolean
  methods?: any
  defaultValue?: any
  onChange?: any
}

function CreatePrjLabelInput({
  type,
  label,
  width,
  height,
  subTitle,
  required = true,
  methods,
  defaultValue,
  onChange,
}: LabelInputProps) {
  const {register, errors, rules, setValue} = useCreateInput(type)
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const getRequiredAtom = useRecoilValue(requiredAtom)
  const getPrjNameAtom = useRecoilValue(prjNameAtom)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [uploadedRequired, setUploadedRequired] = useState(false)

  const {pathname: router} = useLocation()
  const [status, toggleStatus] = useToggle()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      setUploadedFiles(Array.from(files))
      router.includes('/project/modify') ? onChange(event.target.files) : ''
    }
    setUploadedRequired(true)
  }

  const {data} = useQuery({
    queryKey: [QUERY_KEYS.prjList],
    queryFn: () => handleProjectList(),
  })

  useEffect(() => {
    if (defaultValue) {
      setValue(type, defaultValue)
    }
  }, [defaultValue, setValue, type])

  const renderInputField = () => {
    switch (type) {
      case 'files':
        return (
          <StyledUploadWrapper>
            <StyledAcceptWrapper type="button" onClick={() => toggleStatus()}>
              {status ? '▾' : '▸'} 가능한 파일 확장자
              {status && <p>{FILES_ACCEPT}</p>}
            </StyledAcceptWrapper>

            <StyledUploadFileButton>
              {getRequiredAtom ? (
                router.includes('/project/modify') ? (
                  <Container>
                    <UploadArea>
                      {uploadedRequired ? (
                        <StyledUploadFile
                          id={'modifyFile'}
                          type="file"
                          {...register('files', {required: rules.required})}
                          multiple
                          onChange={handleFileChange}
                          modify={true}
                          accept={FILES_ACCEPT.join(',')}
                        />
                      ) : (
                        <StyledUploadFile
                          id={'modifyFile'}
                          type="file"
                          {...register('files')}
                          multiple
                          onChange={handleFileChange}
                          modify={true}
                          accept={FILES_ACCEPT.join(',')}
                        />
                      )}

                      <FileContainer>
                        <UploadButton htmlFor={`modifyFile`}>업로드</UploadButton>
                      </FileContainer>
                    </UploadArea>
                  </Container>
                ) : (
                  <StyledUploadFile
                    id={type}
                    type="file"
                    {...register('files', {required: rules.required})}
                    multiple
                    onChange={handleFileChange}
                    accept={FILES_ACCEPT.join(',')}
                  />
                )
              ) : (
                <StyledUploadFile
                  id={type}
                  type="file"
                  {...register('files', {required: false})}
                  multiple
                  onChange={handleFileChange}
                  accept={FILES_ACCEPT.join(',')}
                />
              )}
            </StyledUploadFileButton>

            <StyledFileList>
              <StyledFileListTitle>Upload Files</StyledFileListTitle>

              {uploadedFiles.length === 0
                ? defaultValue?.map((file: any, index: number) => (
                    <StyledFileName key={index}>{file.file_name}</StyledFileName>
                  ))
                : uploadedFiles?.map((file: any, index: number) => (
                    <StyledFileName key={index}>{file.name}</StyledFileName>
                  ))}
            </StyledFileList>
          </StyledUploadWrapper>
        )

      case 'prj_start_version':
      case 'version_number':
        return (
          <StyledLabelInput
            id={type}
            type={type}
            {...register(type, {required: rules.required, pattern: rules.pattern})}
            width={width}
            height={height}
            placeholder={rules.required}
            disabled={type === 'version_number' && !router.includes('/create')}
          />
        )

      case 'prj_dev_user':
      case 'prj_sec_user':
        return <Dropdown methods={methods} type={type} register={register} rules={rules} />

      case 'rgst_user_id':
        return router.includes('/project/modify') ? (
          <input
            id="updt_user_id"
            type="number"
            {...register('updt_user_id')}
            defaultValue={getAuthStateAtom.userId}
            style={{display: 'none'}}
          />
        ) : (
          <input
            id="rgst_user_id"
            type="number"
            {...register(type)}
            defaultValue={getAuthStateAtom.userId}
            style={{display: 'none'}}
          />
        )

      case 'prj_id':
        return (
          router.includes('/project/modify' || '/createVersion') && (
            <div style={{display: 'none'}}>
              <Controller
                control={methods}
                name="prj_id"
                render={({field: {onChange, value}}) => (
                  <Select
                    options={data?.data}
                    value={data?.data.find((val: any) => val.prj_name === value)}
                    onChange={(c: any) => onChange(c.prj_id)}
                    getOptionLabel={val => val.prj_name}
                    getOptionValue={val => val.prj_name}
                    placeholder={rules.required}
                  />
                )}
              />
            </div>
          )
        )

      case 'prj_name':
        return router.includes('/project/modify') ? (
          <StyledLabelInput
            id="prj_name"
            type="prj_name"
            {...register(type)}
            placeholder={rules.required}
            width={width}
            height={height}
            defaultValue={defaultValue}
          />
        ) : router === '/create' ? (
          <StyledLabelInput
            id="prj_name"
            type="prj_name"
            {...register(type, {required: rules.required})}
            placeholder={rules.required}
            width={width}
            height={height}
            defaultValue={defaultValue}
          />
        ) : (
          router === '/createVersion' && (
            <input
              id="prj_name"
              type="prj_name"
              {...register('prj_name')}
              style={{display: 'none'}}
              defaultValue={getPrjNameAtom}
            />
          )
        )

      default:
        return (
          <>
            {getRequiredAtom ? (
              <StyledLabelInput
                id={type}
                type={type}
                {...register(type, {
                  validate: (value: string | number | undefined) => {
                    if ((!value || value === '') && !defaultValue) {
                      return rules.required
                    }
                    return true
                  },
                  pattern: rules.pattern,
                })}
                width={width}
                height={height}
                placeholder={rules.required}
                defaultValue={defaultValue}
              />
            ) : (
              <StyledLabelInput
                id={type}
                type={type}
                {...register(type)}
                width={width}
                height={height}
                placeholder={rules.required}
                defaultValue={defaultValue}
              />
            )}
          </>
        )
    }
  }

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel htmlFor={type}>
          {label} {required && <StyledRequired>*</StyledRequired>}
          {type === 'version_number' && (
            <StyledVersionExplain>버전은 수정이 불가합니다.</StyledVersionExplain>
          )}
        </StyledLabel>
      )}

      {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      <ErrorMessage
        name={type}
        errors={errors}
        render={({message}) => <StyledError>{message}</StyledError>}
      />
      {renderInputField()}
    </StyledWrapper>
  )
}

export default CreatePrjLabelInput

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledSubTitle = styled.span`
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.light};
  color: ${theme.color.gray700};
`

const StyledLabel = styled.label`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.3rem;
`

const StyledRequired = styled.span`
  color: ${theme.color.red};
`

const StyledLabelInput = styled.input<{width?: string; height?: string}>`
  all: unset;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '40px')};
  padding-left: 10px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  font-size: 0.9rem;
`

const StyledUploadFileButton = styled.div`
  width: 650px;
  height: 40px;
  font-weight: ${theme.fontWeight.bold};
`

const StyledUploadWrapper = styled.div`
  width: 100%;
  font-weight: ${theme.fontWeight.light};
`

const StyledUploadFile = styled.input<{modify?: boolean}>`
  all: unset;
  display: ${({modify}) => (modify === true ? 'none' : 'flex')};
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const StyledError = styled.p`
  color: ${theme.color.red};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
`

const StyledVersionExplain = styled.span`
  align-items: flex-end;
  margin-left: 3px;
  color: ${theme.color.blue700};
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
`

const StyledFileListTitle = styled.div`
  padding-bottom: 4px;
  border-bottom: 2px solid ${theme.color.gray100};
`

const StyledFileList = styled.ul`
  list-style: none;
  margin: 10px 0;
  overflow: auto;
  max-height: 300px;
`

const StyledFileName = styled.li`
  margin: 5px 0;
  color: ${theme.color.gray700};
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  width: 100%;
`

const UploadArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const UploadButton = styled.label`
  padding: 5px 10px;
  font-size: 1rem;
  color: ${theme.color.black};
  background-color: ${theme.color.purple100};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledAcceptWrapper = styled.button`
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: 10px;

  p {
    letter-spacing: 0.4px;
    color: ${theme.color.blue700};
  }
`
