import {type MRT_ColumnDef} from 'material-react-table'

export type Header = {
  title: string
  status: string
  sec: string
  dev: string
  version: string
  createDate: string
  lastDate: string
}

// accessorKey, header값 필수
// muiTableHeadCellProps로 너비 조절 px단위로도 가능,
// 조절하지 않으면 동일한 간격으로 설정되는듯
export const ProjectData: MRT_ColumnDef<Header>[] = [
  {
    accessorKey: 'title',
    header: 'title',
    muiTableHeadCellProps: {
      sx: {
        width: '20%',
      },
    },
  },
  {
    accessorKey: 'status',
    header: 'status',
    muiTableHeadCellProps: {
      sx: {
        width: '10%',
      },
    },
  },
  {
    accessorKey: 'sec',
    header: 'sec',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'dev',
    header: 'dev',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'version',
    header: 'version',
    muiTableHeadCellProps: {
      sx: {
        width: '10%',
      },
    },
  },
  {
    accessorKey: 'createDate',
    header: 'createDate',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'lastDate',
    header: 'lastDate',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
]

export const ProjectHistoryData: MRT_ColumnDef<Header>[] = [
  {
    accessorKey: 'prj_name',
    header: 'title',
    muiTableHeadCellProps: {
      sx: {
        width: '20%',
      },
    },
  },
  {
    accessorKey: 'sec_user_name',
    header: '보안담당자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'dev_user_name',
    header: '개발담당자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'version_number',
    header: '버전',
    muiTableHeadCellProps: {
      sx: {
        width: '10%',
      },
    },
  },
  {
    accessorKey: 'rgst_dtm',
    header: '생성일',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'updt_dtm',
    header: '최종 수정',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
]

export const AccountColumnData: MRT_ColumnDef<Header>[] = [
  {
    accessorKey: 'userId',
    header: 'No',
    muiTableHeadCellProps: {
      sx: {
        width: '2%',
      },
    },
  },
  {
    accessorKey: 'userName',
    header: '이름',
    muiTableHeadCellProps: {
      sx: {
        width: '7%',
      },
    },
  },
  {
    accessorKey: 'loginId',
    header: '이메일',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'userRole',
    header: '담당',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'rgstDtm',
    header: '가입일자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
]

export const ProjectColumnData: MRT_ColumnDef<Header>[] = [
  {
    accessorKey: 'prj_name',
    header: 'title',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'step_status',
    header: 'status',
    muiTableHeadCellProps: {
      sx: {
        width: '7%',
      },
    },
  },
  {
    accessorKey: 'sec_user_name',
    header: '보안 담당자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'dev_user_name',
    header: '개발 담당자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'version_number',
    header: '버전',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'rgst_dtm',
    header: '생성일',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
  {
    accessorKey: 'updt_dtm',
    header: '최종 수정',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
]

export const NoticeColumnData: MRT_ColumnDef<Header>[] = [
  {
    accessorKey: 'noti_id',
    header: 'No',
    muiTableHeadCellProps: {
      sx: {
        width: '2%',
      },
    },
  },
  {
    accessorKey: 'notice_title',
    header: '제목',
    muiTableHeadCellProps: {
      sx: {
        width: '20%',
      },
    },
  },
  {
    accessorKey: 'user_name',
    header: '작성자',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },

  {
    accessorKey: 'rgst_dtm',
    header: '작성일',
    muiTableHeadCellProps: {
      sx: {
        width: '15%',
      },
    },
  },
]
