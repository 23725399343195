import {styled} from 'styled-components'

interface Props {
  title: string
  inform: string
}

function Inform({title, inform}: Props) {
  return (
    <StyledWrapper>
      <StyledSummary>{title}</StyledSummary>
      <StyledSummary>{inform}</StyledSummary>
    </StyledWrapper>
  )
}

export default Inform

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1px 0;
`

const StyledSummary = styled.span`
  margin-right: 0.7rem;
`
