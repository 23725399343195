import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
  handleAcceptProcess,
  handleCreateComment,
  handleDeleteComment,
  handleProcessInfo,
  handleRegistProcess,
  handleUpdateComment,
} from 'src/apis/process'
import {handleProjectDetail} from 'src/apis/project'
import documentIcon from 'src/assets/icons/document.webp'
import linkIcon from 'src/assets/icons/link.webp'
import Button from 'src/components/button'
import Comment from 'src/components/comment'
import CommentInfo from 'src/components/comment/commentInfo'
import SimpleUpload from 'src/components/form/simpleUpload/SimpleUpload'
import Modal from 'src/components/modal'
import Tab from 'src/components/tab/Tab'
import {API_STATUS_SUCCESS} from 'src/constants/api'
import {FILES_ACCEPT} from 'src/constants/inputMessage'
import QUERY_KEYS from 'src/constants/queryKey'
import handleDownload from 'src/hooks/useDownload'
import useToggle from 'src/hooks/useToggle'
import {DataTab} from 'src/pages/project/process/data'
import {commentResetAtom} from 'src/stores/project'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import {ProcessInfoType} from 'src/types/process'
import {ProjectListType} from 'src/types/project'
import styled from 'styled-components'

function Process() {
  const navigate = useNavigate()
  const param = useParams()
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const queryClient = useQueryClient()
  const [stepLink, setStepLink] = useState('')
  const [tempLink, setTempLink] = useState('')
  const [stepDescription, setStepDescription] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [files, setFiles] = useState<FileList | null>(null)
  const [linkError, setLinkError] = useState(false)
  const [tempLinkError, setTempLinkError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmRegistration, setConfirmRegistration] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [status, toggleStatus] = useToggle()
  const [liskFormStatus, toggleLiskFormStatus] = useToggle()

  const setCommentResetAtom = useSetRecoilState(commentResetAtom)

  // 수정 모드
  const handleEditClick = () => {
    setIsEditing(true)
  }
  // 수정 취소
  const handleCancelClick = () => {
    setIsEditing(false)
  }
  // 링크 유효성 검사
  const isValidLink = (link: string) => {
    const regex =
      /^(https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/[a-zA-Z0-9#\/\-_?&=]+)?(#\w*)?$/
    return regex.test(link)
  }
  // 운영중, 운영중지 상태인 경우는 마지막단계(모의해킹)로 이동
  const selectedProcess = DataTab.find(
    obj =>
      obj.projectId ===
      (param?.processId === '4' ? '3' : param?.processId === '5' ? '3' : param?.processId),
  )?.projectId

  // 탭이동
  const handleClickProcessTab = async ({index}: {index: number}) => {
    navigate(`/process/${param?.projectId}/${param?.versionId}/${index}`)
    if (isEditing) {
      window.location.reload()
    }
  }

  const onFilesChange = (files: FileList | null) => {
    setFiles(files)
  }

  // 코멘트 등록
  const createComment = async (data: {files: FileList; [key: string]: any}) => {
    const formData = new FormData()

    // 파일 리스트를 FormData에 추가
    if (data.files) {
      Array.from(data.files).forEach(file => {
        formData.append('files', file)
      })
    }

    // 나머지 데이터를 FormData에 추가
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== 'files') {
        formData.append(key, data[key])
      }
    }
    try {
      const response = await handleCreateComment(formData)
      if (response?.status === API_STATUS_SUCCESS) {
        queryClient.invalidateQueries(processInfo)
        // window.location.reload()
        setCommentResetAtom('Y')
        return true
      }
    } catch (error) {
      console.error('Error creating comment:', error)
      return false
    }
  }

  const handleProcessRegist = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    const formData = new FormData()
    if (isEditing) {
      if (files) {
        Array.from(files).forEach(file => {
          formData.append('files', file)
        })
      }
      setIsEditing(false)
      formData.append('prj_id', `${param.projectId}`)
      formData.append('version_number', `${param.versionId}`)
      formData.append('step_number', `${param.processId}`)
      formData.append('step_lnk', tempLink)
      formData.append('step_description', tempDescription)
      formData.append('rgst_user_id', `${getAuthStateAtom.userId}`)
    } else {
      if (files) {
        Array.from(files).forEach(file => {
          formData.append('files', file)
        })
      }
      // 나머지 데이터를 FormData에 추가
      formData.append('prj_id', `${param.projectId}`)
      formData.append('version_number', `${param.versionId}`)
      formData.append('step_number', `${param.processId}`)
      formData.append('step_lnk', stepLink)
      formData.append('step_description', stepDescription)
      formData.append('rgst_user_id', `${getAuthStateAtom.userId}`)
    }
    try {
      await handleRegistProcess(formData).then(data => {
        if (data?.status === API_STATUS_SUCCESS) {
          queryClient.invalidateQueries(processInfo)
          window.location.reload()
        }
      })
    } catch (error) {
      console.error('Error registering process:', error)
    }
  }

  // 코멘트 업데이트
  const handleUpdateClick = async (commentId: number, newDescription: any) => {
    try {
      const response = await handleUpdateComment({
        comm_id: commentId,
        comment_description: newDescription,
      })
      if (response?.status === API_STATUS_SUCCESS) {
        queryClient.invalidateQueries(processInfo)
        return true
      }
    } catch (error) {
      console.error('Error updating comment:', error)
      return false
    }
  }

  // 코멘트 삭제
  const handleDeleteClick = async (commentId: number) => {
    try {
      const response = await handleDeleteComment(commentId)
      if (response?.status === API_STATUS_SUCCESS) {
        queryClient.invalidateQueries(processInfo)
        return true
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      return false
    }
  }

  // 프로세스 단계별 승인
  const handleAcceptProcessClick = async () => {
    try {
      const response = await handleAcceptProcess(
        parseInt(`${processInfo.data.step_id}`),
        parseInt(`${getAuthStateAtom.userId}`),
      )
      if (response?.staus === API_STATUS_SUCCESS) {
        window.location.reload()
        return true
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      return false
    }
  }

  // 페이지 상단 프로젝트 명/버전
  const projectData: ProjectListType = {
    prj_id: parseInt(`${param.projectId}`),
    version_number: `${param.versionId}`,
  }

  const processData: ProcessInfoType = {
    prj_id: parseInt(`${param.projectId}`),
    version_number: `${param.versionId}`,
    step_number: parseInt(`${param.processId}`),
  }

  const {data: processInfo} = useQuery({
    queryKey: [QUERY_KEYS.procInfo, selectedProcess],
    queryFn: () => handleProcessInfo(processData),
  })

  const {data: projectDetail} = useQuery({
    queryKey: [QUERY_KEYS.prjDetail, processData],
    queryFn: () => handleProjectDetail(projectData),
  })

  const isDisabled =
    (stepLink === '' && files === null) ||
    getAuthStateAtom.userLevel !== 3 ||
    linkError ||
    tempLinkError ||
    projectDetail?.data.step_number !== parseInt(`${param.processId}`)

  const acceptButtonDisabled =
    getAuthStateAtom.userLevel !== 2 ||
    !(
      (processInfo?.data.file || processInfo?.data.step_lnk) &&
      projectDetail?.data.step_number === parseInt(`${param.processId}`)
    )

  useEffect(() => {
    if (stepLink && !isValidLink(stepLink)) {
      setLinkError(true)
    } else {
      setLinkError(false)
    }
  }, [stepLink])

  useEffect(() => {
    if (tempLink && !isValidLink(tempLink)) {
      setTempLinkError(true)
    } else {
      setTempLinkError(false)
    }
  }, [tempLink])

  useEffect(() => {
    if (confirmRegistration) {
      handleProcessRegist({preventDefault: () => {}})
      setConfirmRegistration(false)
    }
  }, [confirmRegistration, processInfo])

  useEffect(() => {
    {
      processInfo?.data.step_description
        ? setTempDescription(processInfo.data.step_description)
        : setTempDescription('')
    }
    {
      processInfo?.data.step_lnk ? setTempLink(processInfo.data.step_lnk) : setTempLink('')
    }
  }, [processInfo])
  // 모달창 닫기
  const handleConfirmRegistration = () => {
    setModalOpen(false)
    setConfirmRegistration(true)
  }

  return (
    <MainContainer>
      {modalOpen && (
        <Modal
          title="최종파일 등록"
          onClick={() => {
            setModalOpen(false)
          }}
          actionClick={() => {
            setModalOpen(false)
            handleConfirmRegistration()
          }}
          rightBtnTitle="등록">
          <StyleAnnouncement>
            * 보안담당자의 승인이 되어야 다음 단계로 넘어갈 수 있습니다.
          </StyleAnnouncement>
        </Modal>
      )}

      {(processInfo && projectDetail) !== undefined && (
        <>
          <ProjectInfoWrapper>
            <Title>{projectDetail.data.prj_name}</Title>
            <ProjectVersion>{projectDetail.data.version_number}</ProjectVersion>
          </ProjectInfoWrapper>
          <StyledWrapper>
            <Tab
              data={DataTab}
              selected={selectedProcess}
              handleClick={handleClickProcessTab}
              currentStep={projectDetail?.data.step_number}
            />
          </StyledWrapper>
          <ProcessConatiner>
            <ProcessWrapper>
              <form onSubmit={handleProcessRegist}>
                <ProcessFileWrapper>
                  <ProcessAlign>
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                      <LiskTitle>파일</LiskTitle>
                      <StyledAcceptWrapper type="button" onClick={() => toggleStatus()}>
                        {status ? '▾' : '▸'} 가능한 파일 확장자
                        {status && <p>{FILES_ACCEPT}</p>}
                      </StyledAcceptWrapper>
                    </div>

                    {processInfo.data.file?.length || processInfo?.data.step_lnk ? (
                      isEditing ? (
                        <>
                          <LiskSubTitle>
                            해당 프로세스 단계와 관련 된 파일을 업로드 해주세요.
                          </LiskSubTitle>

                          <StyledFileInput>
                            {processInfo.data.file?.length !== null ? (
                              <SimpleUpload
                                onFilesChange={onFilesChange}
                                id="processFile"
                                existingFiles={processInfo.data.file}
                              />
                            ) : (
                              <SimpleUpload onFilesChange={onFilesChange} id="processFile" />
                            )}
                          </StyledFileInput>
                        </>
                      ) : processInfo.data.file?.length ? (
                        <LiskWrapper>
                          <FileWrapper>
                            <img src={documentIcon} alt="logo" width={20} />
                            {processInfo.data.file.map((file: any) => (
                              <FileItem
                                key={file.file_id}
                                onClick={() => handleDownload(file.file_path, file.file_name)}>
                                {file.file_name}
                              </FileItem>
                            ))}
                          </FileWrapper>
                        </LiskWrapper>
                      ) : (
                        <LiskWrapper>
                          <FileWrapper>
                            <img src={documentIcon} alt="logo" width={20} />
                            <EnableFileItem> 파일이 없습니다.</EnableFileItem>
                          </FileWrapper>
                        </LiskWrapper>
                      )
                    ) : (
                      <>
                        <LiskSubTitle>
                          해당 프로세스 단계와 관련된 파일을 업로드 해주세요.
                        </LiskSubTitle>
                        <StyledFileInput>
                          <SimpleUpload onFilesChange={onFilesChange} id="processFile" />
                        </StyledFileInput>
                      </>
                    )}
                  </ProcessAlign>
                  <ProcessAlign>
                    <LiskTitle>링크</LiskTitle>

                    {processInfo !== undefined && (
                      <>
                        {processInfo.data.file?.length || processInfo?.data.step_lnk ? (
                          isEditing ? (
                            <>
                              <LiskSubTitle>
                                해당 프로세스 단계와 관련된 링크를 첨부해 주세요
                              </LiskSubTitle>
                              <StyledLinkInput
                                value={tempLink}
                                onChange={e => setTempLink(e.target.value)}
                                placeholder="https://www.example.com"></StyledLinkInput>
                              {tempLinkError && (
                                <ErrorText>
                                  링크 형식이 올바르지 않습니다. 예: https://www.example.com
                                </ErrorText>
                              )}
                            </>
                          ) : processInfo?.data.step_lnk ? (
                            <LiskWrapper>
                              <LinkWrapper>
                                <img src={linkIcon} alt="logo" width={20} />
                                <LinkItem to={`${processInfo.data.step_lnk}`} target="_blank">
                                  {processInfo.data.step_lnk}
                                </LinkItem>
                              </LinkWrapper>
                            </LiskWrapper>
                          ) : (
                            <LiskWrapper>
                              <LinkWrapper>
                                <img src={linkIcon} alt="logo" width={20} />
                                <EnableFileItem> 링크가 없습니다.</EnableFileItem>
                              </LinkWrapper>
                            </LiskWrapper>
                          )
                        ) : (
                          <>
                            <LiskSubTitle>
                              해당 프로세스 단계와 관련된 링크를 첨부해 주세요
                            </LiskSubTitle>
                            <StyledLinkInput
                              value={stepLink}
                              onChange={e => setStepLink(e.target.value)}
                              placeholder="https://www.example.com"></StyledLinkInput>
                            {linkError && (
                              <ErrorText>
                                링크 형식이 올바르지 않습니다. 예: https://www.example.com
                              </ErrorText>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </ProcessAlign>
                </ProcessFileWrapper>
                <LiskTitle>프로세스 설명</LiskTitle>
                {processInfo.data.file?.length || processInfo?.data.step_lnk ? (
                  isEditing ? (
                    <>
                      <ProcessDescription
                        value={tempDescription}
                        onChange={e => setTempDescription(e.target.value)}
                        placeholder="프로세스 관련 내용을 작성 해 주세요.">
                        {processInfo.data.step_description}
                      </ProcessDescription>
                    </>
                  ) : processInfo?.data.step_description ? (
                    <ProcessDescriptionDiv>
                      {processInfo.data.step_description}
                    </ProcessDescriptionDiv>
                  ) : (
                    <ProcessDescriptionDiv>
                      <EnableFileItem></EnableFileItem>
                    </ProcessDescriptionDiv>
                  )
                ) : (
                  <ProcessDescription
                    value={stepDescription}
                    onChange={e => setStepDescription(e.target.value)}
                    placeholder="프로세스 관련 내용을 작성해 주세요.">
                    {processInfo.data.step_description}
                  </ProcessDescription>
                )}
                <ButtonWrapper hasError={getAuthStateAtom.userLevel !== 3}>
                  {getAuthStateAtom.userLevel !== 3 && (
                    <ErrorMessage>※ 등록은 개발담당자만 처리할 수 있습니다.</ErrorMessage>
                  )}
                  {stepLink !== '' ||
                    files !== null ||
                    (getAuthStateAtom.userLevel === 3 &&
                      projectDetail?.data.step_number === parseInt(`${param.processId}`) &&
                      processInfo.data.step_number === undefined && (
                        <ErrorMessage>
                          ※ 파일 혹은 링크 중 최소 하나의 항목은 필수로 등록 해야 합니다.
                        </ErrorMessage>
                      ))}
                  {projectDetail?.data.step_number !== parseInt(`${param.processId}`) &&
                    projectDetail?.data.step_number < parseInt(`${param.processId}`) && (
                      <ErrorMessage>※ 이전 프로세스의 보안담당자 승인이 필요합니다.</ErrorMessage>
                    )}
                  {projectDetail?.data.step_number >= parseInt(`${param.processId}`) &&
                  getAuthStateAtom.userLevel === 3 &&
                  (processInfo.data.file?.length || processInfo?.data.step_lnk) ? (
                    isEditing ? (
                      <>
                        {tempLink !== '' ||
                          files !== null ||
                          (getAuthStateAtom.userLevel === 3 && (
                            <ErrorMessage>
                              ※ 파일 혹은 링크 중 최소 하나의 항목은 필수로 등록 해야 합니다.
                            </ErrorMessage>
                          ))}
                        <StyleMenu>
                          {tempLink !== '' || files !== null ? (
                            <Button
                              type="submit"
                              width="150px"
                              height="40px"
                              bgColor={theme.color.blue300}
                              onClick={handleProcessRegist}>
                              저장
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              width="150px"
                              height="40px"
                              bgColor={theme.color.teal}
                              disabled={true}
                              status="Disabled"
                              style={{cursor: 'not-allowed'}}
                              onClick={handleProcessRegist}>
                              저장
                            </Button>
                          )}
                          <Button
                            type="button"
                            width="150px"
                            height="40px"
                            onClick={handleCancelClick}>
                            취소
                          </Button>
                        </StyleMenu>
                      </>
                    ) : (
                      <Button
                        type="button"
                        width="200px"
                        height="40px"
                        bgColor={theme.color.orange300}
                        onClick={handleEditClick}>
                        등록 수정
                      </Button>
                    )
                  ) : (
                    <Button
                      type="button"
                      width="20%"
                      height="60px"
                      status={isDisabled ? 'Disabled' : 'Enabled'}
                      style={isDisabled ? {cursor: 'not-allowed'} : {}}
                      disabled={isDisabled}
                      onClick={() => {
                        setModalOpen(true)
                      }}>
                      등록
                    </Button>
                  )}
                </ButtonWrapper>
                {parseInt(`${param.processId}`) === 0 && (
                  <LiskFileWrapper>
                    <LiskTitle>
                      리스크 체크<StyledRequired>*</StyledRequired>
                    </LiskTitle>
                    <LiskSubTitle>리스크 체크 후 Comments를 남겨주세요.</LiskSubTitle>
                    {/* <LiskSubTitle>
                      요구사항 단계에서 거쳐야 할 리스크 체크 리스트입니다. 모든 사항 적용 후 제출
                      버튼을 눌러주세요.
                    </LiskSubTitle>

                    <button type="button" onClick={() => toggleLiskFormStatus()}>
                      {liskFormStatus ? '▾' : '▸'} 리스크 체크 항목
                      {liskFormStatus && (
                        <StyledLiskCheckFormWrapper>
                          <LiskCheckForm />
                        </StyledLiskCheckFormWrapper>
                      )}
                    </button> */}
                    <FileContainer
                      onClick={() =>
                        handleDownload(
                          processInfo.data.lstc_file_path,
                          processInfo.data.lstc_file_name,
                        )
                      }>
                      <img src={documentIcon} alt="logo" width={20} />
                      <FileItem>{processInfo.data.lstc_file_name}</FileItem>
                    </FileContainer>
                  </LiskFileWrapper>
                )}
              </form>
            </ProcessWrapper>
            <CommentListWrapper>
              {processInfo.data.commentList !== undefined &&
                processInfo.data.commentList.map((commentList: any) => (
                  <CommentInfo
                    key={commentList.comm_id}
                    status={commentList.user_role}
                    userName={commentList.user_name}
                    rgstDtm={commentList.rgst_dtm}
                    updtDtm={commentList.updt_dtm}
                    files={commentList.files.map((file: any) => (
                      <FileItem
                        key={file.comm_file_id}
                        onClick={() => handleDownload(file.file_path, file.file_name)}>
                        {file.file_name}
                      </FileItem>
                    ))}
                    commentDescription={commentList.comment_description}
                    handleUpdateClick={handleUpdateClick}
                    handleDeleteClick={handleDeleteClick}
                    commentId={commentList.comm_id}></CommentInfo>
                ))}
            </CommentListWrapper>
            <Comment
              handleSubmitForm={createComment}
              completeStepCheck={projectDetail.data.step_number > processInfo.data.step_number}
            />
            <ButtonWrapper hasError={getAuthStateAtom.userLevel !== 2}>
              {getAuthStateAtom.userLevel !== 2 &&
                projectDetail.data.step_number <= parseInt(`${param.processId}`) && (
                  <ErrorMessage>※ 승인은 보안담당자만 처리할 수 있습니다.</ErrorMessage>
                )}

              {(processInfo.data.file?.length || processInfo?.data.step_lnk) &&
              projectDetail.data.step_number === parseInt(`${param.processId}`) ? (
                <Button
                  type="submit"
                  width="20%"
                  height="60px"
                  onClick={handleAcceptProcessClick}
                  status={acceptButtonDisabled ? 'Disabled' : 'Enabled'}
                  style={acceptButtonDisabled ? {cursor: 'not-allowed'} : {}}
                  disabled={acceptButtonDisabled}>
                  승인
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    width="20%"
                    height="60px"
                    onClick={handleAcceptProcessClick}
                    disabled={true}
                    status="Disabled"
                    style={{cursor: 'not-allowed'}}>
                    {projectDetail.data.step_number > processInfo.data.step_number
                      ? '승인완료'
                      : '승인'}
                  </Button>
                </>
              )}
            </ButtonWrapper>
          </ProcessConatiner>
        </>
      )}
    </MainContainer>
  )
}

export default Process

// const StyledLiskCheckFormWrapper = styled.div`
//   margin-top: 15px;
// `

const StyledWrapper = styled.div`
  padding-left: 15%;
  display: flex;
  width: 100%;
  margin-top: 24px;
  border-bottom: 1px solid;
`

const MainContainer = styled.div`
  padding-top: 35px;
`

const ProcessConatiner = styled.div`
  margin: 0 auto;
  width: 80%;
`

const ProjectInfoWrapper = styled.div`
  display: flex;
  padding-left: 15%;
  gap: 20px;
  align-items: center;
`

const ProjectVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 30px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
  background-color: ${theme.color.green300};
`

const ProcessWrapper = styled.div`
  display: flex;
  width: 90%;
  margin-top: 24px;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
`

const ProcessAlign = styled.div`
  width: 50%;
`

const Title = styled.p`
  font-size: 1.875rem;
  font-weight: ${theme.fontWeight.bold};
`

const LiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
`

const LiskFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 1000px;
  padding: 30px;
`

const LiskTitle = styled.p`
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: ${theme.fontWeight.bold};
  padding-top: 30px;
  margin-bottom: 5px;
`

const LiskSubTitle = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${theme.color.black};
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  padding: 0 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 10px;
`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${theme.color.black};
  text-decoration: underline;
  width: 90%;
  padding: 0 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${theme.color.black};
  text-decoration: underline;
  width: 100%;
  padding: 0 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FileItem = styled.div`
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const EnableFileItem = styled.div`
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${theme.color.gray700};
`

const LinkItem = styled(Link)`
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ProcessFileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CommentListWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`

const ProcessDescription = styled.textarea`
  width: 100%;
  height: 200px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;

  &::placeholder {
    color: ${theme.color.gray700};
  }
`
const ProcessDescriptionDiv = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
`

const StyledFileInput = styled.div`
  margin-top: 15px;
`

const StyledLinkInput = styled.input`
  width: 100%;
  border: 1px solid ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 15px;
  padding: 10px;
  font-size: 1rem;
  height: 42px;

  &::placeholder {
    color: ${theme.color.gray700};
  }
`

const StyledRequired = styled.span`
  color: ${theme.color.red};
`

const StyleAnnouncement = styled.span`
  color: ${theme.color.green500};
`

const ButtonWrapper = styled.div<{hasError: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({hasError}) => (hasError ? '30px' : '30px')};
  padding-bottom: 50px;
  flex-direction: column;
  width: 100%;
`

const ErrorMessage = styled.p`
  color: ${theme.color.red};
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
`

const ErrorText = styled.p`
  color: ${theme.color.red};
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1rem;
  margin-top: 10px;
`

const StyleMenu = styled.div`
  font-size: 1.25rem;
  padding: 0 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledAcceptWrapper = styled.button`
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  margin-left: 10px;
  margin-bottom: 5px;

  p {
    letter-spacing: 0.4px;
    color: ${theme.color.blue700};
  }
`
