import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {handleProjectVersionState} from 'src/apis/project'
import documentIcon from 'src/assets/icons/document.webp'
import linkIcon from 'src/assets/icons/link.webp'
import Button from 'src/components/button'
import Status from 'src/components/status'
import handleDownload from 'src/hooks/useDownload'
import {prjUnStpAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import DateFormat from 'src/utils/dateFormat'
import styled from 'styled-components'
import Modal from './modal'
import {API_STATUS_SUCCESS} from 'src/constants/api'
interface File {
  file_id: number
  file_path: string
  file_name: string
}

interface ProjectDetailProps {
  prj_name?: string
  rgst_dtm?: string
  step_number?: number
  prj_description?: string
  version_number?: string
  dev_user_name?: string
  sec_user_name?: string
  file?: File[]
  prj_id?: string
  prj_lnk: string
}

function ProjectDetail({
  prj_name,
  rgst_dtm,
  prj_description,
  step_number,
  version_number,
  dev_user_name,
  sec_user_name,
  file,
  prj_id,
  prj_lnk,
}: ProjectDetailProps) {
  const formattedDate = rgst_dtm ? rgst_dtm.slice(0, 10) : 'No Date'
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)
  const [dateUnStp, setDateUnStp] = useRecoilState(prjUnStpAtom)

  // 상태값에 따라 다른 ui적용
  const getStatusComponent = (step: number) => {
    switch (step) {
      case 0:
        return <Status title="1단계" />
      case 1:
        return <Status title="2단계" />
      case 2:
        return <Status title="3단계" />
      case 3:
        return <Status title="4단계" />
      case 4:
        return <Status title="운영중" />
      default:
        return <Status title="미운영" />
    }
  }

  const handleVersionState = async () => {
    const response = await handleProjectVersionState(prj_id!, version_number!)

    if (response?.status === API_STATUS_SUCCESS) {
      const endDate = new Date()
      setModalOpen(false)
      location.reload()
      setDateUnStp(DateFormat(endDate))
    }
  }

  return (
    <MainContainer>
      {modalOpen && (
        <Modal
          title="프로젝트 미운영"
          onClick={() => setModalOpen(false)}
          rightBtnTitle="미운영"
          actionClick={handleVersionState}>
          {prj_name}의 {version_number} 버전을
          <br />
          미운영 처리 하시겠습니까?
        </Modal>
      )}
      <StyledTopWrapper>
        <ProjectDate>{formattedDate} ~</ProjectDate>
        <StyledRowWrapper>
          {step_number !== 5 && (
            <>
              <Button
                bgColor={`${theme.color.gray700}`}
                width="65px"
                height="33px"
                onClick={() => navigate(`/project/modify/${prj_id}`)}>
                수정
              </Button>
              <Button
                bgColor={`${theme.color.gray700}`}
                width="75px"
                height="33px"
                onClick={() => setModalOpen(true)}>
                미운영
              </Button>
            </>
          )}

          {(step_number == 4 || step_number == 5) && (
            <Button
              width="85px"
              height="35px"
              onClick={() => navigate(`/createVersion`)}
              bgColor={`${theme.color.blue300}`}>
              버전 생성
            </Button>
          )}
        </StyledRowWrapper>
      </StyledTopWrapper>

      <ProjectAlign>
        <ProjectName>{prj_name}</ProjectName>
        {step_number ? getStatusComponent(step_number) : <Status title="1단계" />}
        <Status title="latest" />
      </ProjectAlign>
      <ProjectDescription>{prj_description}</ProjectDescription>
      <FileList>
        <ProjectAlign>
          <ProjectVersion>{version_number}</ProjectVersion>
          <VersionAlign>
            <img src={documentIcon} alt="logo" width={15} decoding="async" />
            {file?.length ? (
              file.map(file => (
                <FileItem
                  key={file.file_id}
                  onClick={() => handleDownload(file.file_path, file.file_name)}>
                  {file.file_name}
                </FileItem>
              ))
            ) : (
              <p>업로드 파일이 없습니다.</p>
            )}
          </VersionAlign>
        </ProjectAlign>
      </FileList>
      <ItemContainer>
        <DevItem>
          보안담당자 | {sec_user_name} &nbsp;&nbsp;&nbsp; 개발담당자 | {dev_user_name}
        </DevItem>
        <LinkItem to={prj_lnk} target="_blank">
          <img src={linkIcon} alt="logo" width={16} />
          &nbsp;피그마 페이지로 이동
        </LinkItem>
      </ItemContainer>
    </MainContainer>
  )
}

export default ProjectDetail

const MainContainer = styled.div`
  background-color: ${theme.color.white};
  padding: 19px 27px;
  margin: 0 auto;
  border-radius: 12px;
  margin-top: 4px;
`

const StyledTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const ProjectDate = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`

const ProjectName = styled.div`
  font-size: 1.25rem;
  font-weight: ${theme.fontWeight.bold};
  display: flex;
  align-items: center;
`

const ProjectDescription = styled.div`
  font-size: 1.1rem;
`

const ProjectAlign = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const VersionAlign = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
  color: ${theme.color.black};
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  padding: 0 20px;
  height: 30px;
  background-color: ${theme.color.gray50};
  border-radius: 5px;
`

const FileList = styled.div`
  padding-top: 20px;
`

const FileItem = styled.div`
  width: fit-content;
  max-width: 300px;

  font-size: 0.8rem;
  color: black;
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DevItem = styled.div`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 0.9rem;
  color: ${theme.color.black};
  padding-top: 10px;
`

const LinkItem = styled(Link)`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1rem;
  opacity: 0.9;
  color: ${theme.color.black};
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const ProjectVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 30px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 0.9rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
  background-color: ${theme.color.gray100};
  padding-left: 10px;
  padding-right: 10px;
`
