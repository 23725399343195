import {Link} from 'react-router-dom'
import MainCardLayout from 'src/components/mainCard/Layout'
import {NOTION_LINK} from 'src/constants/url'
import theme from 'src/styles/theme'
import styled from 'styled-components'

//* 개발 담당자 전용 컴포넌트
function CreateCard() {
  return (
    <MainCardLayout title="Create" subTitle="개발 담당자는 프로젝트 생성 권한이 부여됩니다.">
      <StyledDescription>
        버전 생성을 원하실 경우 특정 프로젝트 선택 시 특정 프로젝트에 대한 버전을 생성할 수
        있습니다.
      </StyledDescription>
      <StyledBtnContainer>
        <StyledBtnWrapper to="/create">
          <StyledTitle>프로젝트 생성</StyledTitle>
          <StyledSubTitle>새로운 프로젝트를 생성합니다</StyledSubTitle>
        </StyledBtnWrapper>
        <StyledBtnWrapper to={NOTION_LINK} bgColor={`${theme.color.blue100}`}>
          <StyledTitle>프로젝트 생성 가이드</StyledTitle>
          <StyledSubTitle>프로젝트 생성 시 가이드 및 유의사항을 확인해 주세요</StyledSubTitle>
        </StyledBtnWrapper>
      </StyledBtnContainer>
    </MainCardLayout>
  )
}

export default CreateCard

const StyledBtnWrapper = styled(Link)<{bgColor?: string}>`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 10px;
  padding: 12px 20px;
  margin-top: 12px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#cdd7f7')};
  cursor: pointer;
`

const StyledTitle = styled.span`
  font-weight: ${theme.fontWeight.semiBold};
  font-size: 1.2rem;
`

const StyledSubTitle = styled.span`
  font-weight: ${theme.fontWeight.light};
  font-size: 0.9rem;
  color: #323232b0;
`

const StyledDescription = styled.div`
  color: ${theme.color.gray700};
  font-weight: ${theme.fontWeight.light};
  font-size: 1.1rem;
  margin-top: 10px;
`
const StyledBtnContainer = styled.div`
  padding-bottom: 8px;
`
