import {useQuery} from '@tanstack/react-query'
import {Controller, UseFormRegister, UseFormReturn} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import Select from 'react-select'
import {useRecoilValue} from 'recoil'
import {handleDvList} from 'src/apis/sign'
import Loading from 'src/components/Loading'
import QUERY_KEYS from 'src/constants/queryKey'
import {devUserAtom, requiredAtom, secUserAtom} from 'src/stores/project'
import {CreateProjectValuesType} from 'src/types/project/create'

interface Props {
  methods: UseFormReturn<CreateProjectValuesType, any, undefined>
  type: 'prj_dev_user' | 'prj_sec_user'
  register: UseFormRegister<CreateProjectValuesType>
  rules?: {
    required?: string
  }
}

function Dropdown({methods, type, register, rules}: Props) {
  const getSecUserAtom = useRecoilValue(secUserAtom)
  const getDevUserAtom = useRecoilValue(devUserAtom)
  const getRequiredAtom = useRecoilValue(requiredAtom)

  const {data, error, isLoading} = useQuery({
    queryKey: [type === 'prj_dev_user' ? QUERY_KEYS.dvListDevUser : QUERY_KEYS.dvListSecUser],
    queryFn: type === 'prj_dev_user' ? () => handleDvList('3') : () => handleDvList('2'),
  })

  if (error) {
    return <div>데이터를 불러오는데 실패했습니다.</div>
  }

  if (isLoading) {
    return <Loading />
  }

  const {pathname: router} = useLocation()

  const defaultValue = router.includes('/create')
    ? []
    : data.data.filter((option: any) =>
        (type === 'prj_dev_user' ? getDevUserAtom : getSecUserAtom).includes(option.userId),
      )

  return (
    <Controller
      control={methods.control}
      {...register(type, {
        validate: (value: any) => {
          if (!getRequiredAtom) {
            return true
          }
          if (defaultValue) {
            if (Array.isArray(value) ? value.length === 0 : !value) {
              return rules?.required
            }
          }
        },
      })}
      name={type}
      defaultValue={defaultValue.map((option: {userId: number}) => option.userId)}
      render={({field: {onChange, value}}) => (
        <Select
          options={data.data}
          value={data.data.filter((option: {userId: number}) =>
            Array.isArray(value) ? value.includes(option.userId) : value === option.userId,
          )}
          onChange={selectedOptions => {
            onChange(selectedOptions.map(value => value.userId))
          }}
          getOptionLabel={option => option.userName}
          getOptionValue={option => option.userId}
          placeholder={rules?.required}
          isMulti
        />
      )}
    />
  )
}

export default Dropdown
