import {Box} from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_TableContainer,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {Header} from 'src/constants/ColumnData'
import {versionAtom} from 'src/stores/project'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface TableDataProps {
  columns: MRT_ColumnDef<Header>[]
  data: any[]
  title?: string
  subTitle?: string
  onRowSelect?: (rowId: any) => void
}

// 너비 90%로 설정
function Table({columns, data, title, subTitle, onRowSelect}: TableDataProps) {
  const navigate = useNavigate()
  const {pathname: router} = useLocation()
  const setControlVersionAtom = useSetRecoilState(versionAtom)

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSortingRemoval: false,
    enableColumnActions: false,
    muiPaginationProps: {
      color: 'primary',
      showRowsPerPage: false,
    },
    paginationDisplayMode: 'pages',
    initialState: {showGlobalFilter: true},
    // onclick
    muiTableBodyRowProps: ({row}) => ({
      onClick: event => {
        if (onRowSelect) {
          setControlVersionAtom(row.original.version_number)
          onRowSelect(row)
        } else if (router.includes(`/main`)) {
          navigate(`/project/detail/${row.original.prj_id}`)
        } else if (router == '/account') {
          navigate('')
        } else if (router.includes(`/notice`)) {
          navigate(`/notice/detail/${row.original.noti_id}`)
        }
      },
      sx: {
        cursor: 'pointer',
      },
    }),

    muiTableHeadCellProps: {
      sx: {
        fontWeight: 'bold',
        fontSize: '1rem',
        fontFamily: 'Pretendard',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontWeight: `${theme.fontWeight.semiBold}`,
        fontSize: '1rem',
        fontFamily: 'Pretendard',
      },
    },
  })

  return (
    <TableWrapper>
      <Box sx={{border: 'gray 2px', width: '90%'}}>
        <Box
          sx={{
            display: 'flex',
            borderRadius: '6px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '32px',
            paddingBottom: '32px',
          }}>
          <div>
            <StyledTitle>{title}</StyledTitle>
            <SubTitle>{subTitle}</SubTitle>
          </div>
          <Box
            sx={{
              width: '400px',
              '& .MuiInputBase-root': {
                backgroundColor: theme.color.gray100, // input 배경 색상 변경
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.color.gray100, // 아웃라인 색상 변경
                },
              },
            }}>
            <MRT_GlobalFilterTextField table={table} />
          </Box>
        </Box>
        <MRT_TableContainer table={table} />
        <Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <MRT_TablePagination table={table} />
          </Box>
        </Box>
      </Box>
    </TableWrapper>
  )
}

export default Table

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.color.white};
  border-radius: 12px 12px 0px 0px;
`

const StyledTitle = styled.p`
  font-size: 1.875rem;
  font-weight: ${theme.fontWeight.bold};
`

const SubTitle = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
`
