const QUERY_KEYS = {
  dvList: 'dvList',
  prjHistoryList: 'prjHistoryList',
  prjLatestVersion: 'prjLatestVersion',
  prjDetail: 'prjDetail',
  procInfo: 'procInfo',
  prjList: 'prjList',
  dvListDevUser: 'dvListDevUser',
  dvListSecUser: 'dvListSecUser',
  prjListStatus: 'prjListStatus',
  modifyPrj: 'modifyPrj',

  notice: 'notice',
  noticeDetail: 'noticeDetail',
} as const

export default QUERY_KEYS
