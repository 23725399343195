import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {handleLogin} from 'src/apis/sign'
import logoImg from 'src/assets/icons/signinLogo.webp'
import CommonLink from 'src/components/CommonLink'
import LabelInput from 'src/components/form/labelInput'
import AuthLayout from 'src/components/layout/AuthLayout'
import {API_STATUS_FAILED, API_STATUS_SUCCESS} from 'src/constants/api'
import {authStateAtom} from 'src/stores/user'
import {SignFormValuesType, SignInFormValuesType} from 'src/types/user'
import styled from 'styled-components'

//* 로그인 page
function Signin() {
  const setAuthState = useSetRecoilState(authStateAtom)
  const navigate = useNavigate()
  const methods = useForm<SignFormValuesType>({mode: 'all'})

  const onSubmit = async (data: SignInFormValuesType) => {
    try {
      const response = await handleLogin(data)
      if (response?.status === API_STATUS_FAILED) {
        methods.setError(
          'login_id',
          {message: '회원 정보를 다시 확인해 주세요.'},
          {shouldFocus: true},
        )
      }

      if (response?.status === API_STATUS_SUCCESS) {
        localStorage.setItem('token', response.data.token)

        setAuthState({
          isAuthenticated: true,
          userName: response.data.userName,
          userId: response.data.userId,
          userLevel: response.data.userLevel,
        })

        navigate('/main')
      }
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <AuthLayout
        buttonTitle="로그인"
        handleSubmit={onSubmit}
        methods={methods}
        bottomContent={
          <>
            <CommonLink to="/forgotPassword" title="비밀번호 찾기" />
            <CommonLink to="/signup" title="계정 생성" />
          </>
        }>
        <StyledImgWrapper>
          <img src={logoImg} alt="logo" />
        </StyledImgWrapper>

        <LabelInput type="login_id" label="Email" />
        <LabelInput type="login_pw" label="Password" />
      </AuthLayout>
    </FormProvider>
  )
}

export default Signin

const StyledImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
`
