import {Link, useNavigate} from 'react-router-dom'
import logoImg from 'src/assets/icons/logo.png'
import {useAuth} from 'src/contexts/AuthContext'
import theme from 'src/styles/theme'
import styled from 'styled-components'

function Header() {
  const {authState, logout} = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  return (
    <StyledWrapper>
      {authState.isAuthenticated ? (
        <Link to="/main">
          <img src={logoImg} alt="logo" width="70px" />
        </Link>
      ) : (
        <Link to="/">
          <img src={logoImg} alt="logo" width="70px" />
        </Link>
      )}

      {authState.isAuthenticated && (
        <StyledAuthInfo>
          <span>{authState.userName}</span>
          <StyledLogoutButton onClick={handleLogout}>logout</StyledLogoutButton>
        </StyledAuthInfo>
      )}
    </StyledWrapper>
  )
}

export default Header

const StyledWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 60px;
  padding: 0 30px;
  padding-top: 2px;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-weight: ${theme.fontWeight.bold};
`

const StyledAuthInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const StyledLogoutButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 248, 255, 0.203);
  width: 50px;
  border-radius: 4px;
  color: ${theme.color.white};
  cursor: pointer;
  font-size: 0.8rem;
`
