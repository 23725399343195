import {FormProvider, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {handleNewPassword} from 'src/apis/sign'
import CommonLink from 'src/components/CommonLink'
import LabelInput from 'src/components/form/labelInput'
import AuthLayout from 'src/components/layout/AuthLayout'
import {API_STATUS_FAILED, API_STATUS_SUCCESS} from 'src/constants/api'
import {SignFormValuesType, SignNewPasswordType} from 'src/types/user'
import {validatePasswordCheck} from 'src/utils/validation'

//* 비밀번호 변경 page
function ChangePassword() {
  const methods = useForm<SignFormValuesType>({mode: 'all'})
  const navigate = useNavigate()

  const onSubmit = async (data: SignNewPasswordType) => {
    const passwordCheck = methods.watch('pw_check')
    if (passwordCheck !== undefined) {
      if (!validatePasswordCheck('new_password', passwordCheck, methods)) {
        return false
      }
    }

    try {
      const response = await handleNewPassword(data)

      if (response?.status === API_STATUS_FAILED) {
        methods.setError(
          'current_password',
          {message: '현재 비밀번호가 일치하지 않습니다.'},
          {shouldFocus: true},
        )
      }

      if (response?.status === API_STATUS_SUCCESS) {
        navigate('/')
      }
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <AuthLayout
        methods={methods}
        pageTitle="비밀번호 변경"
        buttonTitle="재설정"
        handleSubmit={onSubmit}
        bottomContent={
          <>
            <CommonLink to="/changePassword" title="비밀번호 변경" />
            <CommonLink to="/" title="Sign In" />
          </>
        }>
        <LabelInput type="login_id" label="Email" />
        <LabelInput type="current_password" label="Current Password" />
        <LabelInput type="new_password" label="New Password" />
        <LabelInput type="pw_check" label="Verify Password" />
      </AuthLayout>
    </FormProvider>
  )
}

export default ChangePassword
