/* eslint-disable  @typescript-eslint/no-explicit-any */
import {useState} from 'react'
import useAuthInput from 'src/hooks/useAuthInput'
import theme from 'src/styles/theme'
import styled from 'styled-components'

function SignupRadio() {
  const [selectedValue, setSelectedValue] = useState('')
  const {register, rules} = useAuthInput('user_level')
  const [checked, setChecked] = useState(false)

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value)
    setChecked(!checked)
  }

  return (
    <StyledWrapper>
      <StyledInWrapper>
        <input
          type="radio"
          value={3}
          checked={checked}
          {...register('user_level', {
            pattern: rules.pattern,
          })}
          onChange={handleChange}
        />
        <StyledLabelWrapper>개발 담당자</StyledLabelWrapper>
      </StyledInWrapper>
      <StyledInWrapper>
        <input
          type="radio"
          value="2"
          checked={!checked}
          {...register('user_level', {
            pattern: rules.pattern,
          })}
          onChange={handleChange}
        />
        <StyledLabelWrapper>보안 담당자</StyledLabelWrapper>
      </StyledInWrapper>
    </StyledWrapper>
  )
}

export default SignupRadio

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const StyledInWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const StyledLabelWrapper = styled.label`
  font-size: 0.85rem;
  font-weight: ${theme.fontWeight.light};
  color: ${theme.color.gray700};
`
