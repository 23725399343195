import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import Button from 'src/components/button'
import SimpleUpload from 'src/components/form/simpleUpload/SimpleUpload'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface CommentProps {
  commentDescription?: string
  handleSubmitForm: (data: any) => void
  completeStepCheck?: any
}

function Comment({commentDescription, handleSubmitForm, completeStepCheck}: CommentProps) {
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const param = useParams()
  const [files, setFiles] = useState<FileList | null>(null)

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: {
      comment_description: commentDescription || '',
      rgst_user_id: `${getAuthStateAtom.userId}`,
      prj_id: `${param.projectId}`,
      version_number: `${param.versionId}`,
      step_number: `${param.processId}`,
      files: files,
    },
  })
  const onFilesChange = (files: FileList | null) => {
    setFiles(files)
  }

  const onSubmit = (data: any) => {
    handleSubmitForm({
      ...data,
      files,
    })
    reset({
      comment_description: '',
      rgst_user_id: `${getAuthStateAtom.userId}`,
      prj_id: `${param.projectId}`,
      version_number: `${param.versionId}`,
      step_number: `${param.processId}`,
      files: null,
    })
  }

  return (
    <CommentWrapper>
      <CommentTitle>Add a comment</CommentTitle>

      {getAuthStateAtom.userLevel === 3 ? (
        <CommentSubTitle>
          <StyledRequired>* </StyledRequired>이 단계에서 문제 해결을 위한 코드 수정이나 기능 개선에
          대한 의견을 남겨 주세요.
        </CommentSubTitle>
      ) : (
        <CommentSubTitle>
          <StyledRequired>* </StyledRequired>이 단계에서 보안 관련 사항에 대한 검토 및 의견을 남겨
          주세요.
        </CommentSubTitle>
      )}
      <MainContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="comment_description"
            control={control}
            rules={{required: '코멘트가 작성 되지 않았습니다.'}}
            render={({field}) => (
              <>
                {completeStepCheck ? (
                  <CommentDescription
                    disabled
                    placeholder="해결 된 단계에서는 더이상 코멘트를 등록 할 수 없습니다."
                    {...field}
                  />
                ) : (
                  <CommentDescription placeholder="코멘트를 작성해주세요." {...field} />
                )}
                {errors.comment_description && (
                  <ErrorMessage>{errors.comment_description.message}</ErrorMessage>
                )}
              </>
            )}
          />
          <ContentContainer>
            {completeStepCheck ? (
              '\u00A0'
            ) : (
              <SimpleUpload onFilesChange={onFilesChange} id="commentFile" />
            )}
            {completeStepCheck ? (
              <Button
                type="submit"
                width="150px"
                disabled={true}
                status="Disabled"
                style={{cursor: 'not-allowed'}}>
                Comment
              </Button>
            ) : (
              <Button type="submit" width="150px">
                Comment
              </Button>
            )}
          </ContentContainer>
        </form>
      </MainContainer>
    </CommentWrapper>
  )
}

export default Comment

const MainContainer = styled.div`
  border: 2px solid ${theme.color.gray500};
  background-color: ${theme.color.white};
  border-radius: 12px;
`

const CommentDescription = styled.textarea`
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 150px;
  border: 2px solid ${theme.color.gray500};
  border-radius: 10px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
  color: black;
  resize: none; /* Prevent resizing */
  &::placeholder {
    color: ${theme.color.gray700};
    font-size: 1rem;
  }
`

const ContentContainer = styled.div`
  padding: 15px 0;
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
`

const CommentWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-top: 100px;
`

const CommentTitle = styled.p`
  font-size: 1.5rem;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.black};
`

const CommentSubTitle = styled.p`
  font-size: 1rem;
  color: ${theme.color.gray700};
  padding-bottom: 12px;
`

const ErrorMessage = styled.p`
  color: ${theme.color.red};
  font-size: 0.875rem;
  padding-left: 5%;
  margin-top: 10px;
`

const StyledRequired = styled.span`
  color: ${theme.color.red};
`
