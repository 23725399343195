import {useState} from 'react'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface StepProps {
  step: number
  handleClick: any
  unStep?: boolean
}

const steps = [
  {
    label: '요구사항',
    step: 0,
  },
  {
    label: '설계서 업로드',
    step: 1,
  },
  {
    label: '코드 업로드',
    step: 2,
  },
  {
    label: '모의해킹',
    step: 3,
  },
]

function Progress({handleClick = () => void {}, step, unStep}: StepProps) {
  const [activeStep, setActiveStep] = useState<number>(step)

  return (
    <StepContainer>
      {steps.map(({step, label}) => (
        <StepWrapper key={step}>
          <StyledHrWrapper>
            <StepStyle
              unStep={unStep}
              step={
                activeStep === step ? 'working' : activeStep > step ? 'completed' : 'inCompleted'
              }>
              {activeStep > step ? (
                <StyledCheckMark>{unStep ? '☁︎' : '✔'}</StyledCheckMark>
              ) : activeStep === step ? (
                <StepCount>{step + 1}</StepCount>
              ) : (
                <StepNoProgressCount>{step + 1}</StepNoProgressCount>
              )}
            </StepStyle>
            {step !== 3 && (
              <StyledHr
                step={
                  activeStep === step ? 'working' : activeStep > step ? 'completed' : 'inCompleted'
                }
                unStep={unStep}
              />
            )}
          </StyledHrWrapper>

          <StepLabel onClick={() => handleClick({id: step})} disabled={step > activeStep}>
            {label} &gt;
          </StepLabel>
        </StepWrapper>
      ))}
    </StepContainer>
  )
}

export default Progress

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15%;
  height: 105px;
  width: 1100px;

  background-color: ${theme.color.white};
  border-radius: 12px;
  margin-top: 4px;
`

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 4px;
  width: 100%;
`

const StepStyle = styled.div<{step: string; unStep?: boolean}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({step}) =>
    step === 'completed' ? `${theme.color.blue700}` : 'working' ? `${theme.color.white}` : ''};

  border: 1px solid
    ${({step}) =>
      step === 'completed'
        ? 'none'
        : step === 'working'
          ? `${theme.color.blue700}`
          : `${theme.color.gray500}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

const StepCount = styled.span`
  font-size: 0.5rem;
  color: ${theme.color.blue700};
`

const StepNoProgressCount = styled.span`
  font-size: 0.5rem;
  color: ${theme.color.gray500};
`

const StepLabel = styled.button<{disabled: boolean}>`
  font-weight: ${theme.fontWeight.bold};
  color: ${props => (props.disabled ? theme.color.gray300 : theme.color.black)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
`

const StyledCheckMark = styled.div`
  font-size: 0.5rem;
  color: ${theme.color.white};
`

const StyledHrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const StyledHr = styled.hr<{step: string; unStep?: boolean}>`
  border: ${({step, unStep}) =>
    unStep == true
      ? `1px dashed ${theme.color.gray300}`
      : `1px solid ${step === 'completed' ? theme.color.blue700 : step === 'working' ? 'none' : 'none'}`};

  width: ${({step}) => (step === '4' ? 0 : '100%')};
`
