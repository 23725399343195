import {Link} from 'react-router-dom'
import theme from 'src/styles/theme'
import {styled} from 'styled-components'

interface Props {
  to: string
  title: string
}

function CommonLink({to, title}: Props) {
  return <StyledLink to={to}>{title}</StyledLink>
}

export default CommonLink

const StyledLink = styled(Link)`
  all: unset;
  color: ${theme.color.black};
  cursor: pointer;
`
