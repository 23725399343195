import {useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import documentIcon from 'src/assets/icons/document.webp'
import meatball from 'src/assets/icons/meatball.webp'
import Button from 'src/components/button'
import Modal from 'src/components/modal'
import Status from 'src/components/status'
import {authStateAtom} from 'src/stores/user'
import theme from 'src/styles/theme'
import styled from 'styled-components'

interface CommentProps {
  status: string
  userName: string
  rgstDtm: string
  updtDtm?: string
  files: any
  commentDescription: any
  handleClick?: any
  commentId: number
  handleUpdateClick: (commentId: number, newDescription: any) => void
  handleDeleteClick: (commentId: number) => void
}

function CommentInfo({
  status,
  userName,
  rgstDtm,
  updtDtm,
  files = [],
  commentDescription,
  commentId,
  handleClick = () => void {},
  handleUpdateClick,
  handleDeleteClick,
}: CommentProps) {
  const getAuthStateAtom = useRecoilValue(authStateAtom)
  const [isEditing, setIsEditing] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [editedComment, setEditedComment] = useState(commentDescription)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  // 수정 모드
  const handleEditClick = () => {
    setIsEditing(true)
    setIsMenuOpen(false)
  }

  const handleUpdateClickWrapper = () => {
    handleUpdateClick(commentId, editedComment)
    setIsEditing(false)
  }
  const handleDeleteClickWrapper = (e: {preventDefault: () => void}) => {
    handleDeleteClick(commentId)
    setIsEditing(false)
  }

  // 수정 취소
  const handleCancelClick = () => {
    setEditedComment(commentDescription)
    setIsEditing(false)
  }
  // 메뉴 토글
  const handleMenuToggle = () => {
    setIsMenuOpen(prev => !prev)
  }
  // 상태값에 따라 다른 ui적용
  const getStatusComponent = (step: string) => {
    switch (step) {
      case '보안담당자':
        return <Status title="보안" />
      case '개발담당자':
        return <Status title="개발" />
      case '시스템관리자':
        return <Status title="관리" />
      default:
        return <Status title="보안" />
    }
  }
  useEffect(() => {
    if (confirmDelete) {
      handleDeleteClickWrapper({preventDefault: () => {}})
      setConfirmDelete(false)
    }
  }, [confirmDelete])

  const handleConfirmDelete = () => {
    setModalOpen(false)
    setConfirmDelete(true)
  }
  return (
    <MainContainer onSubmit={handleClick}>
      {modalOpen && (
        <Modal
          title="코멘트 삭제"
          onClick={() => {
            setModalOpen(false)
          }}
          actionClick={() => {
            setModalOpen(false)
            handleConfirmDelete()
          }}
          rightBtnTitle="삭제">
          코멘트를 삭제하시겠습니까?
        </Modal>
      )}
      <TitleContainer>
        <TitleWrapper>
          <TitleItem>{getStatusComponent(status)}</TitleItem>
          <TitleItem>{userName}</TitleItem>
          <RgstItem>
            {updtDtm === null
              ? `commented 
          ${rgstDtm}`
              : `commented 
          ${updtDtm}`}
          </RgstItem>
        </TitleWrapper>
        {getAuthStateAtom.userName === userName && (
          <>
            <StyleMenuContainer>
              <img
                src={meatball}
                alt="menu"
                width={20}
                height={20}
                onClick={handleMenuToggle}
                style={{cursor: 'pointer'}}
              />
              {isMenuOpen && (
                <MenuDropdown>
                  <Button
                    type="button"
                    width="100px"
                    height="50px"
                    bgColor={theme.color.white}
                    color={theme.color.black}
                    onClick={handleEditClick}
                    style={{borderRadius: '6px 6px 0 0', border: '1px solid lightGray'}}>
                    수정
                  </Button>
                  <Button
                    type="button"
                    width="100px"
                    height="50px"
                    bgColor={theme.color.gray100}
                    color={theme.color.red}
                    onClick={() => {
                      setIsMenuOpen(false)
                      setModalOpen(true)
                    }}
                    style={{borderRadius: '0 0 6px 6px', border: '1px solid lightGray'}}>
                    삭제
                  </Button>
                </MenuDropdown>
              )}
            </StyleMenuContainer>
          </>
        )}
      </TitleContainer>
      <DivingLine />
      {isEditing ? (
        <CommentDescriptionInput
          value={editedComment}
          onChange={e => setEditedComment(e.target.value)}
          placeholder="댓글을 입력하세요..."
        />
      ) : (
        <CommentDescription>{commentDescription}</CommentDescription>
      )}
      <ContentContainer>
        {files.length === 0 ? (
          '파일이 없습니다.'
        ) : (
          <FileContainer>
            <img src={documentIcon} alt="logo" width={20} />
            {files}
          </FileContainer>
        )}

        {isEditing ? (
          <StyleMenu>
            <Button
              type="button"
              width="150px"
              height="40px"
              bgColor={theme.color.blue300}
              onClick={handleUpdateClickWrapper}>
              저장
            </Button>
            <Button type="submit" width="150px" height="40px" onClick={handleCancelClick}>
              취소
            </Button>
          </StyleMenu>
        ) : (
          ''
        )}
      </ContentContainer>
    </MainContainer>
  )
}

export default CommentInfo

const MainContainer = styled.div`
  border: 2px solid ${theme.color.gray500};
  width: 90%;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 20px;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1%;
  width: 100%;
  gap: 20px;
  height: 50px;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  background-color: ${theme.color.gray100};
`
const RgstItem = styled.div`
  color: ${theme.color.gray700};
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
const CommentDescription = styled.div`
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 100px;
  border-radius: 10px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
  color: black;
  font-weight: ${theme.fontWeight.light};

  ​ &::placeholder {
    color: ${theme.color.gray700};
    font-size: 1rem;
  }
`

const TitleItem = styled.div`
  font-size: 1rem;
  font-weight: ${theme.fontWeight.bold};
`

const StyleMenuContainer = styled.div`
  position: relative;
  display: inline-block;
  padding-right: 20px;
`

const MenuDropdown = styled.div`
  position: absolute;
  top: 25px;
  left: -40px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`

const StyleMenu = styled.div`
  font-size: 1.25rem;
  padding-right: 20px;
  padding-left: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ContentContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 1rem;
  color: black;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  height: 42px;
  background-color: ${theme.color.gray50};
  border-radius: 6px;
  margin-top: 10px;
`

const DivingLine = styled.div`
  border: 1px solid ${theme.color.gray50};
`
const CommentDescriptionInput = styled.textarea`
  display: flex;
  width: 95%;
  margin: 0 auto;
  height: 150px;
  border-radius: 10px;
  margin-top: 15px;
  padding: 20px;
  font-size: 1rem;
  color: black;
  border: 2px solid ${theme.color.gray500};
  resize: none;
`

const StyleAnnouncement = styled.span`
  color: ${theme.color.red};
`
