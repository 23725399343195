import lottieBg from 'src/assets/lottieJSON/pc.json'
import LottieWrapper from 'src/components/LottieWrapper'
import theme from 'src/styles/theme'
import styled from 'styled-components'

function MobileWarning() {
  return (
    <StyledWrapper>
      <StyledItemWrapper>
        <StyledItemSizeWrapper>
          <StyledItemInWrapper>
            <StyledLottieWrapper>
              <LottieWrapper lottieData={lottieBg} loop={true} />
            </StyledLottieWrapper>

            <StyledIntroText>
              <StyledWarning>잠깐!</StyledWarning> <br />
              DAS SDL은 모바일 환경을 지원하지 않아요.
            </StyledIntroText>
            <StyledSubSummary>화면의 크기를 늘리거나, pc 환경으로 접속해주세요.</StyledSubSummary>
          </StyledItemInWrapper>
        </StyledItemSizeWrapper>
      </StyledItemWrapper>
    </StyledWrapper>
  )
}

export default MobileWarning

const StyledWrapper = styled.div`
  position: fixed;
  display: block;
  width: 100vw;
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  color: ${theme.color.white};
  z-index: 100;
`

const StyledLottieWrapper = styled.div`
  width: 300px;
`

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledItemSizeWrapper = styled.div`
  height: 100%;
  margin: 0px auto;
`

const StyledItemInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
`

const StyledWarning = styled.span`
  font-size: 1.75rem;
`

const StyledIntroText = styled.span`
  font-size: 1.19rem;
  font-weight: ${theme.fontWeight.bold};
  letter-spacing: 0.02px;
`

const StyledSubSummary = styled.span`
  font-size: 1rem;
  font-weight: ${theme.fontWeight.light};
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
`
