import {Navigate} from 'react-router-dom'
import {useRecoilValue} from 'recoil'
import DashboardLayout from 'src/components/layout/DashboardLayout'
import CreateCard from 'src/components/mainCard/Create'
import Docs from 'src/components/mainCard/Docs'
import InProgress from 'src/components/mainCard/InProgress'
import PeopleCard from 'src/components/mainCard/People'
import SdlManual from 'src/components/mainCard/SdlManual'
import SecurityCard from 'src/components/mainCard/Security'
import {authStateAtom} from 'src/stores/user'
import styled from 'styled-components'

//* 메인 page
function Main() {
  const getAuthStateAtom = useRecoilValue(authStateAtom)

  if (!getAuthStateAtom.isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <DashboardLayout>
      <StyledGridWrapper>
        {getAuthStateAtom.userLevel == 3 && <CreateCard />}
        {getAuthStateAtom.userLevel == 2 && <SecurityCard />}
        {getAuthStateAtom.userLevel == 1 && <PeopleCard />}
        <Docs />
        <StyledInProgressWrapper>
          <InProgress />
        </StyledInProgressWrapper>

        <StyledSdlManualWrapper>
          <SdlManual />
        </StyledSdlManualWrapper>
      </StyledGridWrapper>
    </DashboardLayout>
  )
}

export default Main

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 30px;
  width: 100%;
`

const StyledSdlManualWrapper = styled.div`
  grid-column: span 2;
  grid-row: 2;
`

const StyledInProgressWrapper = styled.div`
  grid-column: 3;
  grid-row: span 2;
`
